<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive mb-0">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Asesmen</th>
                <th>Instruksi</th>
                <th width="150">Aksi</th>
              </tr>
            </thead>
            <template v-for="(v,k) in (isParent.listCPPTRiwayat||[])">
              <tbody :key="k">
                <tr class="table-info">
                  <td colspan="2">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-semibold mr-2">{{v.data.arancps_tanggal | moment("DD MMMM YYYY")}}, 
                        {{v.data.arancps_jam}}</div>
                        <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                      </div>
                    </div>
                  </td>
                  <td rowspan="5">
                    <a href="javascript:;" @click="salinData(v)" class="btn btn-icon btn-sm btn-success ml-2" v-b-tooltip.hover title="Salin CPPT">
                      <i class="icon-file-check2"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div class="req-list d-flex align-items-center" v-if="v.data.arancps_laporan_nilai_kritis == 'Y' && v.data.arancps_laporan_kondisi_pasien == 'Y'">
                          <div v-if="v.data.arancps_laporan_nilai_kritis == 'Y'">
                              <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">Pelaporan Nilai Kritis</b>
                          </div>
                          <div v-if="v.data.arancps_laporan_kondisi_pasien == 'Y'" class="ml-2">
                              <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">Pelaporan Kondisi Pasien</b>
                          </div>
                        </div>
                        <p>
                          Melaporkan Pasien ke DPJP <br />
                          Pasien dengan diagnosa medis <span class="font-weight-semibold">{{v.data.arancps_diagnosa_medis||"-"}}</span>, keluhan utama saat ini <span class="font-weight-semibold">{{v.data.arancps_keluhan_utama||"-"}}</span>. Pasien mempunyai riwayat penyakit: <span class="font-weight-semibold">{{v.data.arancps_riwayat_penyakit||"-"}}</span>.
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="align-top" rowspan="4">
                    <div class="mb-2" v-for="(vA,kA) in (v.advis||[])" :key="kA">
                      <div class="font-weight-semibold">Advis {{kA+1}}: </div>
                      <pre class="pre-input">{{vA.arancsa_advis_text||"-"}}</pre>       
                      
                      <p class="text-danger-800">Perintah Dokter telah dilakukan penulisan, pembacaan perintah ulang dan konfirmasi ke {{vA.nama_dokter||"-"}} oleh <span class="font-weight-semibold">
                      {{v.data.arancps_nama_petugas||"-"}}</span>, Pada tanggal <span class="font-weight-semibold">
                      {{v.data.arancps_tanggal | moment('DD MMMM YYYY')}}
                      </span> jam <span class="font-weight-semibold">{{v.data.arancps_jam}} WIB</span></p>      

                      <a href="javascript:;" v-if="isHaveTindakLanjut(vA)" @click="toDetailSBARAdvis(vA)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>B</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <template v-if="(v.data.arancps_riwayat_alergi||[]).length">
                            <span>Pasien mempunyai riwayat alergi:</span>
                            <ul class="mb-0 pl-3">
                              <li v-for="(vAl,kAl) in (v.data.arancps_riwayat_alergi||[])" :key="kAl">
                                ({{vAl.jenis||"-"}}) {{vAl.name}}
                              </li>
                            </ul>
                          </template>
                          dengan keluhan saat ini <span class="font-weight-semibold">{{v.data.arancps_keluhan_saat_ini||"-"}}</span>, pengobatan yang sudah dilakukan <span class="font-weight-semibold">{{v.data.arancps_pengobatan_yang_dilakukan||"-"}}</span>, riwayat pembedahan <span class="font-weight-semibold">{{v.data.arancps_riwayat_pembedahan||"-"}}</span> riwayat tindakan invasif <span class="font-weight-semibold">

                          <ol class="mb-0" style="margin-left: -35px;" v-if="(v.data.arancps_tindakan_invasif||[]).length">
                            <li v-for="(v,k) in (v.data.arancps_tindakan_invasif||[])" :key="k" class="mt-1 ml-3">
                                {{v}}
                            </li>
                          </ol>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <div class="mb-2">
                            Tanda tanda vital sebagai berikut:
                            <ul class="mb-0 pl-3">
                              <li v-if="v.data.arancps_ttv_tekanan_darah_min && v.data.arancps_ttv_tekanan_darah_max">Tekanan Darah: 
                              <span>
                              {{v.data.arancps_ttv_tekanan_darah_min||"-"}}/
                              {{v.data.arancps_ttv_tekanan_darah_max||"-"}} mmHG
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_nadi && v.data.arancps_ttv_label">Nadi: 
                              <span>
                              {{v.data.arancps_ttv_nadi||"-"}} x/mnt - {{v.data.arancps_ttv_label||"-"}}
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_gula_darah">Gula Darah: 
                              <span>
                              {{v.data.arancps_ttv_gula_darah||"-"}} mg/dL
                              </span>
                              </li>
                              <li v-if="v.data.arancps_ttv_pernafasan">Pernafasan: 
                              <span>
                              {{v.data.arancps_ttv_pernafasan||"-"}} x/mnt
                              </span>
                              </li>
                              <li v-if="v.data.arancps_ttv_spo2">SPO2: 
                              <span>
                              {{v.data.arancps_ttv_spo2||"-"}}%
                              </span>
                              </li>
                              
                              <li v-if="v.data.arancps_ttv_suhu">Suhu: 
                              <span>
                              {{v.data.arancps_ttv_suhu||"-"}}C
                              </span>
                              </li>
                              
                              <li v-if="v.data.arancps_ttv_weight">Berat Badan 
                              <span>
                              {{v.data.arancps_ttv_weight||"-"}} kg
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_height">Tinggi: 
                              <span>
                              {{v.data.arancps_ttv_height||"-"}} cm
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_lingkar_kepala">Lingkar Kepala: 
                              <span>
                              {{v.data.arancps_ttv_lingkar_kepala||"-"}} cm
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_luas_permukaan_anak">Lingkar Dada: 
                              <span>
                              {{v.data.arancps_ttv_luas_permukaan_anak||"-"}} CM<sup>2</sup>
                              </span>
                              </li>

                              <li>Kesadaran: 
                              <span>{{v.data.arancps_ttv_kesadaran}} |     
                                  <span v-if="v.data.arancps_ttv_kesadaran >= 14"> Composmentis</span> 
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 12 && v.data.arancps_ttv_kesadaran <= 13">Apatis</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 10 && v.data.arancps_ttv_kesadaran <= 11">Delirium</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 7 && v.data.arancps_ttv_kesadaran <= 9">Somonolen</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 5 && v.data.arancps_ttv_kesadaran <= 6">Sopor</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 4 && v.data.arancps_ttv_kesadaran <= 4">Semi-Comma</span>
                                  <span v-else>Coma</span>
                                  
                                  <span class="ml-2" style="margin-right:20px;">E: 
                                      {{v.data.arancps_ttv_kesadaran_e||0}} 
                                  </span>
                                  <span style="margin-right:20px;">M: 
                                      {{v.data.arancps_ttv_kesadaran_m||0}}  
                                  </span>
                                  <span style="margin-right:20px;">V: 
                                      {{v.data.arancps_ttv_kesadaran_v||0}}
                                  </span>
                              </span>
                              </li>
                            </ul>
                          </div>
                          <div class="mb-2" v-if="(v.data.arancps_hasil_lab||[]).length">
                            <span class="font-weight-semibold">Tindakan Lab</span>
                            <ol class="mb-0 pl-3">
                              <li v-for="(vL,kL) in (v.data.arancps_hasil_lab||[])" :key="kL">
                                {{vL.value||"-"}}
                              </li>
                            </ol>
                          </div>
                          <div class="mb-2" v-if="(v.data.arancps_hasil_radiologi||[]).length">
                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                            <ol class="mb-0 pl-3">
                              <li v-for="(vR,kR) in (v.data.arancps_hasil_radiologi||[])" :key="kR">
                                {{vR.value||"-"}}
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>R</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <span class="font-weight-semibold">Usulan kolaborasi</span>
                          <ul class="mb-0 pl-3">
                            <li v-for="(vk,kk) in (v.data.arancps_usulan_kolaborasi||[])" :key="kk+'Usulan'">
                              {{vk||"-"}}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-if="!(isParent.listCPPTRiwayat||[]).length">
              <tbody>
                <tr class="table-info">
                  <td colspan="99" class="text-center">Tidak ada Data CPPT</td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
    
    <b-modal v-model="openDetailSBARAdvis" :title="'Detail CPPT SBAR Tindak Lanjut'" size="xl" ok-only ok-title="Tutup">
      <DetailSBARAdvis :rowData="dataSBARAdvis"/>
    </b-modal> 
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import DetailSBARAdvis from "@/components/Ranap/DetailSBARAdvis.vue"

export default {
  extends: GlobalVue,  
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  components: {
    DetailSBARAdvis
  },
  data() {
    return {
      openDetailSBARAdvis: false,
      dataSBARAdvis: {},
    }
  },
  mounted(){
    console.log(this.isParent.listCPPTRiwayat)
  },
  methods: {
    toDetailSBARAdvis(v){
      this.openDetailSBARAdvis = true
      this.dataSBARAdvis = v
    },
    getUserInput(type){
      let text = ''
      if(type == 'DOKTER'){
        text = 'Dokter'
      }else if(type == 'PPALAB'){
        text = 'Laboratorium'
      }else if(type == 'PPARADIO'){
        text = 'Radiologi'
      }else if(type == 'PPAFISIO'){
        text = 'Fisioterapi'
      }else if(type == 'PPAFARMASI'){
        text = 'Farmasi'
      }else if(type == 'DIETISIEN'){
        text = 'Dietisien'
      }else if(type == 'PERAWAT'){
        text = 'Perawat'
      }else {
        text = null
      }
      return text
    },
    isHaveTindakLanjut(v){
      if(v.arancsa_is_resep == "Y" || v.arancsa_is_lab == "Y" || v.arancsa_is_radiologi == "Y" || v.arancsa_is_fisio == "Y" || v.arancsa_is_operasi == "Y" || v.arancsa_is_konsultasi == "Y" || v.arancsa_is_dietisien == "Y" || v.arancsa_is_raber == "Y" || v.arancsa_is_alih_rawat == "Y" || v.arancsa_is_pindah_ruang == "Y" || v.arancsa_is_perawatan == "Y" || v.arancsa_is_done_perawatan == "Y" || v.arancsa_is_rencana_operasi == "Y" || v.arancsa_is_stop_raber == 'Y'
      ){
        return true
      }else{
        return false
      }
    },
    salinData(row){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda yakin untuk menyalin CPPT ini?',
        text: 'Data akan berubah sesuai dengan CPPT yang Anda pilih.',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          let data = {
            type: "salin-riwayat",
            id: this.isParent.row.arancp_id,
            data: row
          }
          this.$swal({
            html: 'Menyalin Data...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          ).then(res=> {
            let resp = res.data
            this.isParent.modalCPPTRiwayat = false
            this.isParent.row.arancps_laporan_kondisi_pasien = resp.row.arancps_laporan_kondisi_pasien
            this.isParent.row.arancps_laporan_nilai_kritis = resp.row.arancps_laporan_nilai_kritis
            this.isParent.row.arancps_keluhan_utama = resp.row.arancps_keluhan_utama
            this.isParent.row.arancps_diagnosa_medis = resp.row.arancps_diagnosa_medis
            this.isParent.row.arancps_riwayat_penyakit = resp.row.arancps_riwayat_penyakit
            this.isParent.row.arancps_riwayat_alergi = resp.row.arancps_riwayat_alergi
            this.isParent.row.arancps_keluhan_saat_ini = resp.row.arancps_keluhan_saat_ini
            this.isParent.row.arancps_pengobatan_yang_dilakukan = resp.row.arancps_pengobatan_yang_dilakukan
            this.isParent.row.arancps_riwayat_pembedahan = resp.row.arancps_riwayat_pembedahan
            this.isParent.row.arancps_tindakan_invasif = resp.row.arancps_tindakan_invasif
            this.isParent.row.arancps_nilai_nyeri = resp.row.arancps_nilai_nyeri
            this.isParent.row.arancps_ttv_tekanan_darah_min = resp.row.arancps_ttv_tekanan_darah_min
            this.isParent.row.arancps_ttv_tekanan_darah_max = resp.row.arancps_ttv_tekanan_darah_max
            this.isParent.row.arancps_ttv_nadi = resp.row.arancps_ttv_nadi
            this.isParent.row.arancps_ttv_label = resp.row.arancps_ttv_label
            this.isParent.row.arancps_ttv_gula_darah = resp.row.arancps_ttv_gula_darah
            this.isParent.row.arancps_ttv_pernafasan = resp.row.arancps_ttv_pernafasan
            this.isParent.row.arancps_ttv_spo2 = resp.row.arancps_ttv_spo2
            this.isParent.row.arancps_ttv_suhu = resp.row.arancps_ttv_suhu
            this.isParent.row.arancps_ttv_weight = resp.row.arancps_ttv_weight
            this.isParent.row.arancps_ttv_height = resp.row.arancps_ttv_height
            this.isParent.row.arancps_ttv_lingkar_kepala = resp.row.arancps_ttv_lingkar_kepala
            this.isParent.row.arancps_ttv_bmi = resp.row.arancps_ttv_bmi
            this.isParent.row.arancps_ttv_kesadaran = resp.row.arancps_ttv_kesadaran
            this.isParent.row.arancps_ttv_kesadaran_e = resp.row.arancps_ttv_kesadaran_e
            this.isParent.row.arancps_ttv_kesadaran_m = resp.row.arancps_ttv_kesadaran_m
            this.isParent.row.arancps_ttv_kesadaran_v = resp.row.arancps_ttv_kesadaran_v
            this.isParent.row.arancps_ttv_luas_permukaan_anak = resp.row.arancps_ttv_luas_permukaan_anak
            this.isParent.row.arancps_pemeriksaan_fisik = resp.row.arancps_pemeriksaan_fisik
            this.isParent.row.arancps_hasil_lab = resp.row.arancps_hasil_lab
            this.isParent.row.arancps_hasil_radiologi = resp.row.arancps_hasil_radiologi
            this.isParent.row.arancps_usulan_kolaborasi = resp.row.arancps_usulan_kolaborasi
            this.isParent.row.arancps_tindak_lanjut_laporan_nilai_kritis = resp.row.arancps_tindak_lanjut_laporan_nilai_kritis
            this.isParent.row.arancps_mohon_advis_selanjutnya = resp.row.arancps_mohon_advis_selanjutnya

            this.$nextTick(() => {
              this.$swal().close()
              
              this.$swal({
                title: `Data Berhasil Disalin`,
                icon: 'success',
              })
            })
            
          }).catch(err => {
            this.loadingOverlay = false
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
      
    },
  }
}

</script>
