<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <validation-observer ref="VFormCPPT">
        <b-form @submit.prevent="doSubmit" class="card">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Tambah CPPT SBAR</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Nama Petugas yang Mengisi <strong class="text-danger">*</strong></label>
                  <b-form-input v-model="row.arancps_nama_petugas" type="text" class="form-control" placeholder="Nama Petugas yang Mengisi"/>
                  <VValidate name="Nama Petugas yang Mengisi" v-model="row.arancps_nama_petugas"
                    :rules="toValidate(mrValidation.arancps_nama_petugas)" />

                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Tanggal <strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <datepicker v-model="row.arancps_tanggal" input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                    </div>
                  </div>
                  <VValidate name="Tanggal" v-model="row.arancps_tanggal"
                    :rules="toValidate(mrValidation.arancps_tanggal)" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Jam <strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancps_jam">
                    </vue-timepicker>
                  </div>
                  <VValidate name="Jam" v-model="row.arancps_jam"
                    :rules="toValidate(mrValidation.arancps_jam)" />
                </div>
              </div>
              <div class="col-md-3">
                <a href="javascript:;" @click="openRiwayatCPPT()" class="btn btn-icon alpha-indigo text-indigo-800 border-indigo float-right" v-b-tooltip.hover :title="'Pilih CPPT Dari Riwayat'">
                  <i class="fas fa-receipt"></i>
                  Pilih CPPT Dari Riwayat
                </a>
              </div>
            </div>
            <div class="form-group">
              <b-form-checkbox v-model="row.arancps_laporan_nilai_kritis" value="Y" unchecked-value="N">Pelaporan Nilai Kritis
              </b-form-checkbox>
              <b-form-checkbox v-model="row.arancps_laporan_kondisi_pasien" value="Y" unchecked-value="N">Pelaporan Kondisi Pasien
              </b-form-checkbox>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="wrap_line p-0">
                  <h3 class="px-3 py-2">Isi Laporan SBAR</h3>
                  <table class="table table-sm border-bottom">
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="cppt-report-content">
                          <div>Melaporkan pasien dengan identitas</div>
                          <ul class="mb-0 pl-3">
                            <li>Nama: {{row.ap_fullname||"-"}}</li>
                            <li>Tgl. Lahir/Usia: {{row.ap_dob | moment('DD MMMM YYYY')}} / {{row.ap_usia_with_ket||"-"}}</li>
                            <li>No. RM: {{row.ap_code||"-"}}</li>
                          </ul>
                          <div class="mb-1 mt-1">Dengan keluhan utama <strong class="text-danger">*</strong>
                            <b-form-textarea v-model="row.arancps_keluhan_utama" id="Keluhan Utama" rows="3" class="form-control"></b-form-textarea>
                            <VValidate name="Keluhan Utama" v-model="row.arancps_keluhan_utama"
                              :rules="toValidate(mrValidation.arancps_keluhan_utama)" />
                          </div>
                          <div class="mb-1 mt-1">Diagnosa Medis<strong class="text-danger">*</strong>
                            <b-form-textarea v-model="row.arancps_diagnosa_medis" id="Diagnosa Medis" rows="3" class="form-control"></b-form-textarea>
                            <VValidate name="Diagnosa Medis" v-model="row.arancps_diagnosa_medis"
                              :rules="toValidate(mrValidation.arancps_diagnosa_medis)" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>B</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="cppt-report-content">
                          <div class="row">
                            <div class="col-lg-7 col-lg-6">
                              <div class="mb-2">
                                <div class="font-weight-semibold">Riwayat Alergi:</div>
                                <table class="table table-sm table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="33%">Jenis</th>
                                      <th>Informasi Alergi</th>
                                      <th width="64"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(v1,k1) in (row['arancps_riwayat_alergi']||[])" :key="k1+'aler'">
                                        <td>
                                            <v-select placeholder="Pilih Jenis"
                                            v-model="v1['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </td>
                                        <td>
                                            <vue-typeahead-bootstrap
                                            v-model="v1['name']"
                                            :data="mAlergi" @input="searchAlergi(v1['name'])" placeholder="Pilih Alergi"
                                            />
                                        </td>
                                        <td>
                                        <a href="javascript:;" @click="removeAlergi(k,k1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                        </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="3" class="text-center">
                                      <a href="javascript:;" @click="addAlergi(k)" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                            <div class="col-lg-5 col-lg-6">
                              <div class="mb-2">
                                <label for="sbarTindakanInvasif">Tindakan Invasif</label>
                                <ol class="mb-0" style="margin-left: -35px;" v-if="(row.arancps_tindakan_invasif||[]).length">
                                    <li v-for="(v,k) in (row.arancps_tindakan_invasif||[])" :key="k+'d'" class="mt-1">
                                    <div class="input-group">
                                        <input v-model="row.arancps_tindakan_invasif[k]" type="text" class="form-control form-control-sm">
                                        <div class="input-group-append">
                                        <a href="javascript:;" 
                                        @click="(row.arancps_tindakan_invasif||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                            <i class="icon-trash-alt"></i>
                                        </a>
                                        <a href="javascript:;" v-if="k == ((row.arancps_tindakan_invasif||[]).length - 1)" @click="row.arancps_tindakan_invasif.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                            <i class="icon-plus2"></i>
                                        </a>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="row.arancps_tindakan_invasif[k]" :rules="{required:1}" />
                                    </li>
                                </ol>
                                <div class="" v-else>
                                    <button type="button" class="btn btn-light mr-3" @click="row.arancps_tindakan_invasif.push('')">Tambah Tindakan</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-2">
                                <label for="sbarKeluhanSaatIni">Keluhan Saat Ini <strong class="text-danger">*</strong></label>
                                <b-form-textarea v-model="row.arancps_keluhan_saat_ini" rows="3" class="form-control"></b-form-textarea>
                                <VValidate name="Keluhan Saat Ini" v-model="row.arancps_keluhan_saat_ini"
                                  :rules="toValidate(mrValidation.arancps_keluhan_saat_ini)" />
                              </div>
                              <div>
                                <label for="sbarKeluhanSaatIni">Pengobatan yang telah dilakukan:</label>
                                <b-form-textarea v-model="row.arancps_pengobatan_yang_dilakukan" rows="3" class="form-control"></b-form-textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-2">
                                <label for="sbarRiwayatPembedahan">Riwayat Pembedahan</label>
                                <b-form-textarea v-model="row.arancps_riwayat_pembedahan" rows="3" class="form-control"></b-form-textarea>
                              </div>
                              <div>
                                <label> Pasien memiliki riwayat penyakit terdahulu: <strong class="text-danger">*</strong> </label>
                                <b-form-textarea v-model="row.arancps_riwayat_penyakit" id="Riwayat Penyakit" rows="3" class="form-control"></b-form-textarea>
                                <VValidate name="Riwayat Penyakit" v-model="row.arancps_riwayat_penyakit"
                                  :rules="toValidate(mrValidation.arancps_riwayat_penyakit)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="cppt-report-content">
                          <div class="mb-2">
                            <div class="font-weight-semibold">Tanda-tanda Vital:</div>
                            <div class="row">
                              <div class="col-md-6 col-lg-3">
                                <div class="mb-2">
                                  <label>Tekanan Darah</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="row.arancps_ttv_tekanan_darah_min" type="text" name="name"
                                      class="form-control" placeholder="Systole" />

                                      <div class="input-group-append input-group-prepend">
                                        <span class="input-group-text">/</span>
                                      </div>

                                      <b-form-input :formatter="number" placeholder="Diastole"
                                      v-model="row.arancps_ttv_tekanan_darah_max" type="text"
                                      class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                      </div>
                                  </div>     
                                </div>
                              </div>

                              <div class="col-md-5 col-lg-2">
                                <div class="mb-2">
                                  <label>Nadi</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="row.arancps_ttv_nadi" type="text" name="name"
                                      class="form-control" placeholder="Nadi" />

                                      <div class="input-group-append input-group-prepend">
                                      <span class="input-group-text">x/mnt</span>
                                      </div>

                                      <div class="input-group-append">
                                          <div style="width: 150px;">
                                              <v-select placeholder="Pilih Label" v-model="row.arancps_ttv_label"
                                              :options="Config.mr.StatusRegular" label="text"
                                              :clearable="true" :reduce="v=>v.value"></v-select>
                                          </div>
                                      </div>

                                  </div>   
                                </div>
                              </div>
                              <div class="col-md-3 col-lg-2">
                                <div class="mb-2">
                                  <label>GDA</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="alphanum"
                                      v-model="row.arancps_ttv_gula_darah" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">mg/dL</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3 col-lg-2">
                                <div class="mb-2">
                                  <label>Pernafasan</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="row.arancps_ttv_pernafasan" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">x/mnt</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3 col-lg-2">
                                <div class="mb-2">
                                  <label>SPO2</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="row.arancps_ttv_spo2" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">%</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3 col-lg-2">
                                <div class="mb-2">
                                  <label>Suhu</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="row.arancps_ttv_suhu" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">C</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3 col-lg-2">
                                <div class="mb-2">
                                  <label>Berat Badan</label>
                                  <div class="input-group">
                                    <b-form-input @input="hitungBMI()" :formatter="number"
                                    v-model="row.arancps_ttv_weight" type="text"
                                    class="form-control" />
                                    <div class="input-group-append">
                                    <span class="input-group-text">kg</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="mb-2">
                                  <label>Tinggi</label>
                                  <div class="input-group">
                                      <b-form-input @input="hitungBMI()" :formatter="number"
                                      v-model="row.arancps_ttv_height" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">cm</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">       
                                  <label>BMI</label>
                                  <div class="input-group">
                                      <b-form-input disabled v-model="row.arancps_ttv_bmi" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">m2</span>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-3 col-lg-2">
                                <div class="mb-2">
                                  <label>Lingkar Kepala</label>
                                  <div class="input-group">
                                      <b-form-input @input="hitungBMI()" :formatter="number"
                                      v-model="row.arancps_ttv_lingkar_kepala" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">cm</span>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="mb-2">
                                  <label>Lingkar Dada</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="row.arancps_ttv_luas_permukaan_anak" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">cm</span>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-5">
                              <div class="mb-2">
                                <label for="sbarPemeriksaanFisik">Tingkat Kesadaran</label>
                                <b-form-input :formatter="number"
                                  v-model="row.arancps_ttv_kesadaran" type="text"
                                  class="form-control" />
                              </div>
                              <div class="mb-2">
                                <div class="form-row">
                                  <div class="col-md-4">
                                    <div><label for="tksE">E</label>
                                    <b-form-input @input="countTK()" :formatter="number"
                                    v-model="row.arancps_ttv_kesadaran_e" type="text"
                                    class="form-control" />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div><label for="tksM">M</label>
                                    <b-form-input @input="countTK()" :formatter="number"
                                    v-model="row.arancps_ttv_kesadaran_m" type="text"
                                    class="form-control" />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div><label for="tksV">V</label>
                                    <b-form-input @input="countTK()" :formatter="number"
                                    v-model="row.arancps_ttv_kesadaran_v" type="text"
                                    class="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-5">
                              <div class="mb-2">
                                <label for="sbarPemeriksaanFisik">Pemeriksaan Fisik</label>
                                <textarea v-model="row.arancps_pemeriksaan_fisik" name="sbarPemeriksaanFisik" id="sbarPemeriksaanFisik" rows="2" class="form-control form-control-sm"></textarea>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-5">
                              <div class="mb-2">
                                <label for="sbarHasilLab">Tindakan Lab</label>
                                <ol class="mb-0" style="margin-left: -35px;" v-if="(row.arancps_hasil_lab||[]).length">
                                    <li v-for="(v,k) in (row.arancps_hasil_lab||[])" :key="k+'a'" class="mt-1">
                                    <div class="input-group">
                                        <input v-model="row.arancps_hasil_lab[k]['value']" type="text" class="form-control form-control-sm">
                                        <div class="input-group-append">
                                        <a href="javascript:;" 
                                        @click="(row.arancps_hasil_lab||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                            <i class="icon-trash-alt"></i>
                                        </a>
                                        <a href="javascript:;" v-if="k == ((row.arancps_hasil_lab||[]).length - 1)" @click="row.arancps_hasil_lab.push({value:''})" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                            <i class="icon-plus2"></i>
                                        </a>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tindakan Lab `+(k+1)" v-model="row.arancps_hasil_lab[k]['value']" :rules="{required:1}" />
                                    </li>
                                </ol>
                                <div class="" v-else>
                                    <button type="button" class="btn btn-light mr-3" @click="row.arancps_hasil_lab.push({value:''})">Tambah Tindakan</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-5">
                              <div class="mb-2">
                                <label for="sbarHasilRadio">Tindakan Radiologi</label>
                                <ol class="mb-0" style="margin-left: -35px;" v-if="(row.arancps_hasil_radiologi||[]).length">
                                    <li v-for="(v,k) in (row.arancps_hasil_radiologi||[])" :key="k+'b'" class="mt-1">
                                    <div class="input-group">
                                        <input v-model="row.arancps_hasil_radiologi[k]['value']" type="text" class="form-control form-control-sm">
                                        <div class="input-group-append">
                                        <a href="javascript:;" 
                                        @click="(row.arancps_hasil_radiologi||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                            <i class="icon-trash-alt"></i>
                                        </a>
                                        <a href="javascript:;" v-if="k == ((row.arancps_hasil_radiologi||[]).length - 1)" @click="row.arancps_hasil_radiologi.push({value:''})" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                            <i class="icon-plus2"></i>
                                        </a>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tindakan Radiologi `+(k+1)" v-model="row.arancps_hasil_radiologi[k]['value']" :rules="{required:1}" />
                                    </li>
                                </ol>
                                <div class="" v-else>
                                    <button type="button" class="btn btn-light mr-3" @click="row.arancps_hasil_radiologi.push({value:''})">Tambah Tindakan</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>R</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="row">
                          <div class="col-md-7 col-lg-5">
                            <div class="mb-2">
                              <label for="sbarHasilLab">Usulan Kolaborasi</label>
                              <ol class="mb-1" style="margin-left: -35px;" v-if="(row.arancps_usulan_kolaborasi||[]).length">
                                <li v-for="(v6,k6) in (row.arancps_usulan_kolaborasi||[])" :key="k6" class="mt-1">
                                  <div class="input-group">
                                      <input v-model="row.arancps_usulan_kolaborasi[k6]" type="text" class="form-control form-control-sm">
                                      <div class="input-group-append">
                                      <a href="javascript:;"
                                      @click="(row.arancps_usulan_kolaborasi||[]).splice(k6,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                          <i class="icon-trash-alt"></i>
                                      </a>
                                      <a href="javascript:;" v-if="k == ((row.arancps_usulan_kolaborasi||[]).length - 1)" @click="row.arancps_usulan_kolaborasi.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                          <i class="icon-plus2"></i>
                                      </a>
                                      </div>
                                  </div>
                                  <VValidate :name="`Usulan Kolaborasi `+(k6)" v-model="row.arancps_usulan_kolaborasi[k6]" :rules="{required:1}" />
                                </li>
                              </ol>
                              <div class="">
                                <button type="button" class="btn btn-light mr-3" @click="row.arancps_usulan_kolaborasi.push('')">Tambah Tindakan</button>
                              </div>
                              
                              <div class="mb-2"></div>
                                <b-form-checkbox v-model="row.arancps_tindak_lanjut_laporan_nilai_kritis" value="Y" unchecked-value="N">
                                Tindak Lanjut Pelaporan Nilai Kritis
                                </b-form-checkbox>
                                <b-form-checkbox v-model="row.arancps_mohon_advis_selanjutnya" value="Y" unchecked-value="N">
                                Mohon Advis Selanjutnya
                                </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div class="wrap_line p-0">
                  <div class="px-3 pt-3">
                    <h3>Perintah Dokter</h3>
                  </div>
                  <div v-for="(v,k) in (advisData||[])" :key="k+'c'">
                    <div class="p-3 border-top">
                      <div>
                        <label for="advisDpjp2">Advis {{k+1}}</label>
                        <b-form-textarea v-model="advisData[k]['arancsa_advis_text']" rows="2" class="form-control"></b-form-textarea>
                        <VValidate :name="'Advis #'+(k+1)" v-model="advisData[k]['arancsa_advis_text']"
                          :rules="{required: 1, min:3, max: 256}" />
                      </div>

                      <div class="row mt-2">
                                  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="" for="">Tanggal <strong class="text-danger">*</strong></label>
                            <div class="input-group">
                              <datepicker v-model="advisData[k]['arancsa_tanggal']" input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar">
                              </datepicker>
                              <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                              </div>
                            </div>
                            <VValidate :name="'Tanggal Advis #'+(k+1)" v-model="advisData[k]['arancsa_tanggal']"
                              :rules="{required: 1}" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="" for="">Jam <strong class="text-danger">*</strong></label>
                            <div class="input-group">
                              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="advisData[k]['arancsa_jam']">
                              </vue-timepicker>
                            </div>
                            <VValidate :name="'Jam Advis #'+(k+1)" v-model="advisData[k]['arancsa_jam']"
                              :rules="{required: 1}" />
                          </div>
                        </div>
                      </div>

                      <!-- Tindakan Lanjut SBAR -->
                      <div class="wrap_line mt-2">
                        <div>
                          <div class="form-group">
                            <label for="opsiTindakLanjut">Tindak Lanjut</label>
                            <div id="opsiTindakLanjut">
                              <div class="row g-2">
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_resep') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_resep" value="Y" unchecked-value="N">
                                    Resep
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_lab') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_lab" value="Y" unchecked-value="N">
                                    Lab
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_radiologi') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_radiologi" value="Y" unchecked-value="N">
                                    Radiologi
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_fisio') != -1 && (user.mpo_id == 1 || user.levelId == 1)">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_fisio" value="Y" unchecked-value="N">
                                    Fisioterapi
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_operasi') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_operasi" value="Y" unchecked-value="N">
                                    Operasi
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_konsultasi') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_konsultasi" value="Y" unchecked-value="N">
                                    Konsultasi
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_dietisien') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_dietisien" value="Y" unchecked-value="N">
                                    Konsultasikan ke Dietisien
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_raber') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_raber" value="Y" unchecked-value="N">
                                    Rawat Bersama
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_alih_rawat') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_alih_rawat" value="Y" unchecked-value="N">
                                    Alih Rawat
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_pindah_ruang') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_pindah_ruang" value="Y" unchecked-value="N">
                                    Pindah Ruang
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_rencana_operasi') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_rencana_operasi" value="Y" unchecked-value="N">
                                    Rencanakan Operasi
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_stop_raber') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_stop_raber" value="Y" unchecked-value="N">
                                    Lepas Rawat Bersama
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_perawatan') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_perawatan" value="Y" unchecked-value="N">
                                    Masih dalam Perawatan
                                  </b-form-checkbox>
                                </div>
                                <div class="col-auto" v-if="showTindakLanjut.indexOf('is_done_perawatan') != -1">
                                  <b-form-checkbox v-model="advisData[k].arancsa_is_done_perawatan" value="Y" unchecked-value="N">
                                    Selesai Perawatan
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div v-if="advisData[k].arancsa_is_resep == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Resep">
                        <ResepDokter :rowData="advisData[k]" :keyData="(k+1)"/>
                      </div>
                      <div v-if="advisData[k].arancsa_is_lab == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Laboratorium">
                        <Laboratorium :rowData="advisData[k]" :keyData="(k+1)"/>
                      </div>
                      <div v-if="advisData[k].arancsa_is_radiologi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Radiologi">
                        <Radiologi :rowData="advisData[k]" :keyData="(k+1)" />
                      </div>
                      <div v-if="advisData[k].arancsa_is_fisio == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Fisioterapi">
                        <Fisioterapi :rowData="advisData[k]" :keyData="(k+1)" />
                      </div>
                      <div v-if="advisData[k].arancsa_is_operasi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Operasi">
                        <Operasi :rowData="advisData[k]" :keyData="(k+1)" />
                      </div>
                      <div v-if="advisData[k].arancsa_is_konsultasi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Konsultasi">
                        <Konsultasi :rowData="advisData[k]" :keyData="(k+1)" />
                      </div>
                      <div v-if="advisData[k].arancsa_is_dietisien == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Dietisien">
                        <Dietisien :rowData="advisData[k]" :keyData="(k+1)" />
                      </div>
                      <div v-if="advisData[k].arancsa_is_raber == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Raber">
                        <Raber :rowData="advisData[k]" :keyData="(k+1)" />
                      </div>
                      <div v-if="advisData[k].arancsa_is_alih_rawat == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                        <AlihRawat :rowData="advisData[k]" :keyData="(k+1)"/>
                      </div>
                      
                      <div v-if="advisData[k].arancsa_is_pindah_ruang == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                        <PindahRuang :rowData="advisData[k]" :keyData="(k+1)"/>
                      </div>
                      
                      <div v-if="advisData[k].arancsa_is_rencana_operasi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                        <RencanaOperasi :rowData="advisData[k]" :keyData="(k+1)"/>
                      </div>

                      <div v-if="advisData[k].arancsa_is_done_perawatan == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                        <SelesaiPerawatan :rowData="advisData[k]" :keyData="(k+1)"/>
                      </div>
                      <!-- Tindakan Lanjut SBAR -->

                      <div class="mt-2" id="advis2DpjpOpt">
                        <label for="advis2DpjpSelect">Pilih DPJP <strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih DPJP" v-model="advisData[k].arancsa_dpjp"
                        :options="mDPJP" label="text" :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <VValidate :name="'DPJP #'+(k+1)" v-model="advisData[k]['arancsa_dpjp']"
                          :rules="{required: 1, max: 256}" />
                      </div>
                      <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                          <b-form-checkbox v-model="advisData[k].aransca_is_confirm" value="Y" unchecked-value="N">
                          Lakukan Tulis, Baca Ulang dan Konfirmasi
                          </b-form-checkbox>
                          <a href="javascript:;" @click="removeAdvis(v,k)" class="btn btn-sm btn-icon btn-outline-danger">
                            <i class="icon-trash-alt"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="px-3 pb-3">
                    <a href="javascript:;" @click="addAdvis" class="btn btn-labeled btn-labeled-left btn-info btn-sm">
                      <b><i class="icon-plus2"></i></b>
                      <span>Tambah Advis</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <a href="javascript:;" @click="back()" class="btn">Kembali</a>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
        </b-form>
      </validation-observer>
    </div>

    <b-modal hide-footer v-model="modalCPPTRiwayat" :title="'Pilih CPPT Dari Riwayat'" size="xl">
      <DataRiwayat /> 
    </b-modal>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

import ResepDokter from './ResepDokter.vue'
import Laboratorium from './Laboratorium.vue'
import Radiologi from './Radiologi.vue'
import Fisioterapi from './Fisioterapi.vue'
import Operasi from './Operasi.vue'
import Konsultasi from './Konsultasi.vue'
import Raber from './Raber.vue'
import AlihRawat from './AlihRawat.vue'
import Dietisien from './Dietisien.vue'
import PindahRuang from './PindahRuang.vue'
import SelesaiPerawatan from './SelesaiPerawatan.vue'
import RencanaOperasi from './RencanaOperasi.vue'
import DataRiwayat from './DataRiwayat.vue'

export default {
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker, PatientInfo, ResepDokter, Laboratorium, Radiologi, Fisioterapi, Operasi, Konsultasi, Raber, AlihRawat, Dietisien, PindahRuang, SelesaiPerawatan, RencanaOperasi, DataRiwayat },
  data(){
    return {
      rowReg: {},
      advisData: [],
      mAlergi: [],
      mDPJP: [],
      
      patientData: {},
      loading: {
          patientInfo: false,
      },

      showTindakLanjut: [],
      
      riwayatObat: [],
      mObatPreferensi: [],
      mObat: [],
      mDosis: [],
      mFaskes: [],
      mPoli: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mDokter: [],
      mJenisRawatInap: [
        {
          "text": "Obsgyn",
          "value": 1
        },
        {
          "text": "Umum",
          "value": 3
        },
        {
          "text": "Perawatan Kritis",
          "value": 4
        }
      ],
      mRanapBangsal: [],
      mRanapKelas: [],
      rowSP: {
        type: ''
      },

      listCPPTRiwayat: [],
      modalCPPTRiwayat: false
    }
  },
  computed: {
    
    validateICOperasi(){
      let isHaveIC = 0
      for(let i = 0; i < (this.selectedOperasiInputData||[]).length; i++){
        if(this.selectedOperasiInputData[i]['inform_consent']){
          isHaveIC = isHaveIC + 1        
        }
      }
      return isHaveIC == (this.selectedOperasiInputData||[]).length
    },
    
    selectedOperasiInputData() {
      let data = []
      for (let u = 0; u < (this.advisData|| []).length; u++) {
        for (let i = 0; i < (this.advisData[u].arancptlo_tindakan || []).length; i++) {
          for (let j = 0; j < (this.advisData[u].arancptlo_tindakan[i]['data'] || []).length; j++) {
              if (this.advisData[u].arancptlo_tindakan[i]['data'][j]['selected']) {
                  data.push(this.advisData[u].arancptlo_tindakan[i]['data'][j])
              }
          }
        }
      }
      return data
    },

    validateAdvis(){
      let is = true
      if((this.advisData||[]).length){
        let val = 0
        for(let i = 0; i < (this.advisData||[]).length; i++){
          if(
            this.advisData[i].arancsa_is_resep == 'Y' ||
            this.advisData[i].arancsa_is_lab == 'Y' ||
            this.advisData[i].arancsa_is_radiologi == 'Y' ||
            this.advisData[i].arancsa_is_fisio == 'Y' ||
            this.advisData[i].arancsa_is_operasi == 'Y' ||
            this.advisData[i].arancsa_is_konsultasi == 'Y' ||
            this.advisData[i].arancsa_is_dietisien == 'Y' ||
            this.advisData[i].arancsa_is_raber == 'Y' ||  
            this.advisData[i].arancsa_is_alih_rawat == 'Y' ||  
            this.advisData[i].arancsa_is_pindah_ruang == 'Y' ||
            this.advisData[i].arancsa_is_perawatan == 'Y' ||
            this.advisData[i].arancsa_is_done_perawatan == 'Y'
          ){
            val = val + 1
          }        
        }
        is = val ? true : false
      }
      return is
    },
  },
  methods: {
    countTK(){
      this.row.arancps_ttv_kesadaran = parseInt(this.row.arancps_ttv_kesadaran_e||0) + parseInt(this.row.arancps_ttv_kesadaran_m||0) + parseInt(this.row.arancps_ttv_kesadaran_v||0)
    },

    autoSaveCPPTDokter: _.debounce(function (data) {
      data.type = 'auto-save-cppt-dokter'
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    showTindakLanjutCPPT(){
      if(this.user.levelId != 1){
        if(this.user.id == this.rowReg.aranr_dokter_id){
          this.showTindakLanjut = [
            'is_resep','is_lab','is_radiologi','is_fisio','is_operasi','is_konsultasi','is_dietisien','is_raber','is_alih_rawat','is_pindah_ruang','is_perawatan','is_done_perawatan'
          ] 
        }else if(this.row.arancp_konsul_id){
          this.showTindakLanjut = [
            'is_resep','is_lab','is_radiologi','is_fisio','is_operasi','is_konsultasi','is_dietisien','is_perawatan'
          ] 
        }else if(this.row.arancp_raber_id){
          this.showTindakLanjut = [
            'is_resep','is_lab','is_radiologi','is_fisio','is_operasi','is_konsultasi','is_dietisien','is_pindah_ruang','is_perawatan','is_rencana_operasi','is_stop_raber'
          ] 
        }else if(this.user.bu_is_dokter_jaga == 'Y' || this.user.bu_is_anestesi == 'Y'){
          this.showTindakLanjut = [ 'is_lab','is_radiologi','is_fisio','is_dietisien','is_resep','is_pindah_ruang','is_perawatan']
        }else {
          this.showTindakLanjut = [
            'is_resep','is_lab','is_radiologi','is_operasi','is_konsultasi','is_dietisien','is_raber','is_alih_rawat','is_pindah_ruang','is_perawatan','is_done_perawatan'
          ]
        }
      }else{
        this.showTindakLanjut = [
          'is_resep','is_lab','is_radiologi','is_fisio','is_operasi','is_konsultasi','is_dietisien','is_raber','is_alih_rawat','is_pindah_ruang','is_perawatan','is_done_perawatan','is_rencana_operasi','is_stop_raber'
        ]
      }
      console.log(this.showTindakLanjut)
    },
    hitungBMI(){
      if(this.row.arancps_ttv_weight && this.row.arancps_ttv_height){
        this.row.arancps_ttv_bmi = this.row.arancps_ttv_weight / ((this.row.arancps_ttv_height/100)*(this.row.arancps_ttv_height/100))

        this.row.arancps_ttv_bmi = this.row.arancps_ttv_bmi.toFixed(2) 
      }else{
        this.row.arancps_ttv_bmi = null
      }
    },
    addAlergi(k){
        this.row['arancps_riwayat_alergi'].push({
            name : '',
            jenis : null,
        })
    },
    removeAlergi(k,k2){
        this.row['arancps_riwayat_alergi'].splice(k2,1)
    },
    searchAlergi: _.debounce(function (e) {
      let data = {
          name: e,
          type: 'get-alergi'
      }
      Gen.apiRest(
          "/do/"+this.modulePage,
          {data:data}, 
          "POST"
      ).then(res=>{
          this.mAlergi = res.data.data            
      })
    }, 100),

    back() {
      this.$router.push({name: 'RanapCPPT', params: {pageSlug: this.pageSlug}}).catch(() => {})
    },
    apiGet(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.cpptNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.cpptNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
          this.showTindakLanjutCPPT()
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.cpptNo){
        Gen.apiRest(
            "/get/"+url+'?master=1', 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
      }    
    },
    
    getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'

      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

  
    toValidate(val) {
      return {
        ...val
      }
    },

    selectedOperasiInput(rowData) {
      let data = []
      for (let i = 0; i < (rowData.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (rowData.arancptlo_tindakan[i]['data'] || []).length; j++) {
            if (rowData.arancptlo_tindakan[i]['data'][j]['selected']) {
                data.push(rowData.arancptlo_tindakan[i]['data'][j]['text'])
            }
        }
      }
      return data.join(", ")
    },
    
    selectedOperasiInputId(rowData) {
      let data = []
      for (let i = 0; i < (rowData.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (rowData.arancptlo_tindakan[i]['data'] || []).length; j++) {
            if (rowData.arancptlo_tindakan[i]['data'][j]['selected']) {
                data.push(rowData.arancptlo_tindakan[i]['data'][j])
            }
        }
      }
      return data
    },
    
    selectedOperasiInformConsent(rowData) {
      let data = []
      for (let i = 0; i < (rowData.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (rowData.arancptlo_tindakan[i]['data'] || []).length; j++) {
            if (rowData.arancptlo_tindakan[i]['data'][j]['selected']) {
                data.push(rowData.arancptlo_tindakan[i]['data'][j]['inform_consent'])
            }
        }
      }
      return data
    },

    doSubmit(){
      if(this.row.arancp_is_active == 'N'){
        return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
        }).then(result => {
            if (result.value) {
              setTimeout(()=>{
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for(let i = 0; i < (el||[]).length; i++){
                      if(el[i].style.display !== 'none'){
                          inv.push(el[i].id)
                      }
                  }
                  if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
              },500)
            }
        })
      }

      let isOperasi = 0
      for(let i = 0; i < (this.advisData||[]).length; i++){
        if(this.advisData[i].arancsa_is_operasi == 'Y'){
          isOperasi = isOperasi + 1 
        }
      }

      if(isOperasi){
        if(!(this.selectedOperasiInputData||[]).length){
          return this.$swal({
            icon: 'error',
            title: 'Tidak dapat Lanjut',
            text: 'Tidak ada tindakan operasi dipilih'
          })
        }
        if(!this.validateICOperasi){
          return this.$swal({
            icon: 'error',
            title: 'Tidak dapat Lanjut',
            text: 'Tindakan Operasi Memerlukan Inform Consent'
          })
        }
      }

      
      let isPindah = 0
      for(let i = 0; i < (this.advisData||[]).length; i++){
        if(this.advisData[i].arancsa_is_pindah_ruang == 'Y' && this.advisData[i].aranctlpr_type){
          isPindah = isPindah + 1 
        }
      } 

      if (isPindah) {
        let isFail = 0
        for(let i = 0; i < (this.advisData||[]).length; i++){
          if(this.advisData[i].aranctlpr_type == "ICU"){
            if(!(this.advisData[i].aranctlpr_icu_ttv||[]).length && !(this.advisData[i].aranctlpr_icu_lab||[]).length && !(this.advisData[i].aranctlpr_icu_radiologi||[]).length && !(this.advisData[i].aranctlpr_icu_pemeriksaan_fisik||[]).length){
              isFail = isFail + 1
            } 
          } else if(this.advisData[i].aranctlpr_type == "NICU"){
            if(!(this.advisData[i].aranctlpr_picu_ttv||[]).length && !(this.advisData[i].aranctlpr_picu_radiografi||[]).length){
              isFail = isFail + 1
            } 
          } else if(this.advisData[i].aranctlpr_type == "AIRBORNE"){
            if(!this.advisData[i].aranctlpr_airborne){
              isFail = isFail + 1
            } 
          } else if(this.advisData[i].aranctlpr_type == "IMUNOSUPRESI"){
            if(!this.advisData[i].aranctlpr_imunosupresi){
              isFail = isFail + 1
            } 
          } else if(this.advisData[i].aranctlpr_type == "KICU"){
            if(!(this.advisData[i].aranctlpr_kicu_ttv||[]).length && !(this.advisData[i].aranctlpr_kicu_nilai_lab||[]).length){
              isFail = isFail + 1
            } 
          } else if(this.advisData[i].aranctlpr_type == "KNICU"){
            if(!(this.advisData[i].aranctlpr_knicu_kriteria_umum||[]).length && !(this.advisData[i].aranctlpr_knicu_ttv||[]).length){
              isFail = isFail + 1
            }
          } else if(this.advisData[i].aranctlpr_type == "KAIRBORNE"){
            if(!(this.advisData[i].aranctlpr_kairborne||[]).length){
              isFail = isFail + 1
            }
          } else if(this.advisData[i].aranctlpr_type == "KIMUNOSUPRESI"){
            if(!(this.advisData[i].aranctlpr_kimunosupresi||[]).length){
              isFail = isFail + 1
            }
          }
        }
      
        if(isFail){
          return this.$swal({
            icon: 'error',
            title: 'Tidak dapat Lanjut',
            text: 'Kriteria Pasien Pindah Ruang Belum Diisi'
          })
        }
      }
      
      // if(!this.validateAdvis){
      //   return this.$swal({
      //     icon: 'error',
      //     title: 'Tidak dapat Lanjut',
      //     text: 'Tindak lanjut belum diisi'
      //   })
      // }

      this.$refs['VFormCPPT'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              data.advisData = this.advisData
              data.dataSP = this.rowSP
      
              for(let i = 0; i < (data.advisData||[]).length; i++){
                data.advisData[i]['tindakanOperasi'] = this.selectedOperasiInput(data.advisData[i])
                data.advisData[i]['tindakanOperasiId'] = this.selectedOperasiInputId(data.advisData[i])                
                data.advisData[i]['tindakanIC'] = this.selectedOperasiInformConsent(data.advisData[i])

              }

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: 'RanapCPPT', params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },

    autoSaveAdvisData: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-advis',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
            "/do/" + this.modulePage, {
            data: dataPost
            },
            "POST"
        )
      }
    }, 1000),  

    
    addAdvis(v = null) {
      let data = {
        arancsa_arancp_id: this.row.arancp_id,
        arancsa_advis_text: null,
        arancsa_dpjp: this.rowReg.aranr_dokter_id,
        id: null
      }
      data.type = 'add-advis'
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
          this.apiGet()
      })
    },
    
    removeAdvis(v, k) {
      let data = {
          type: 'remove-advis',
          id: v.arancsa_id
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
          this.advisData.splice(k, 1)
      })
    }, 
    
    openRiwayatCPPT(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'get-data-riwayat',
          id_reg: this.rowReg.aranr_id,
          id: this.row.arancp_id,
        }
      }, 'POST').then(res => {
        this.loadingOverlay = false
        this.modalCPPTRiwayat = true
        this.listCPPTRiwayat = res.data.listCPPTRiwayat
      })
    }

  },
  mounted() {
    this.apiGet()

    this.getPatientInfo()
  },
  watch: {
    row: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    advisData: {
      handler(v) {
        this.autoSaveAdvisData(v)
      },
      deep: true
    }
  }
}

</script>