<template>
  <div>
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Laboratorium</h6>
    </div>
    <div class="card-body p-3">
      <div class="row g-2">
        <div class="col-md-4">
          <div>
            <label for="">Prioritas Pemeriksaan <strong class="text-danger">*</strong></label>
            <div>
                <b-form-radio-group 
                :options="Config.mr.configPrioritasPemeriksaan"
                v-model="rowData['arancptll_prioritas']" />
            </div>
            <VValidate :name="'Prioritas Pemeriksaan Laboratorium' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_prioritas"
                :rules="toValidate(isParent.mrValidation.arancptll_prioritas)" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="req-list-resume">
        <div class="row g-3">
        <template v-for="(v,k) in rowData.arancptll_data">
            <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
            <div class="card shadow-0 border">
                <div class="card-header py-2 bg-light">
                <h6 class="card-title text-uppercase font-weight-semibold">
                    {{v.head||"-"}}</h6>
                </div>
                <div class="card-body py-2">
                <template v-for="v1,k1 in (v.dubData||[])">
                    <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                    :key="k1+'labdatas'">
                    <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                    <template v-for="v2,k2 in (v1.data||[])">
                        <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                        <i
                            class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <b class="align-middle font-weight-semibold">{{v2.text||"-"}}</b>
                        <span class="" v-if="v2.notes">, {{v2.notes}}</span>
                        </div>
                    </template>
                    </div>
                </template>
                </div>
            </div>
            </div>
        </template>

        <div class="col-md-4 col-lg-3" v-if="rowData.arancptll_data_lainnya">
            <div class="card shadow-0 border">
            <div class="card-header py-2 bg-light">
                <h6 class="card-title text-uppercase font-weight-semibold">Item
                Pemeriksaan Lainnya</h6>
            </div>
            <div class="card-body py-2">
                <div class="req-list">
                <div>
                    <i
                    class="icon-checkmark-circle text-success align-middle mr-1"></i>
                    <b class="align-middle font-weight-semibold">{{rowData.arancptll_data_lainnya||"-"}}</b>
                </div>
                </div>
            </div>
            </div>
        </div>
        <!--
        <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
        -->
        </div>

        <div class="row g-3 mt-3" v-if="rowData.arancptll_is_transfusi == 'Y'">
          <div class="col-lg-12">
              <div class="card shadow-0 border">
              <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">Tindakan Transfusi</h6>
              </div>
              <div class="card-body py-2">

                <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Indikasi Transfusi</h4>
                              <p>{{rowData.arancptll_tr_indikasi_transfusi||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Golongan Darah</h4>
                              <p>{{rowData.arancptll_tr_golongan_darah||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Jumlah Kantong</h4>
                              <p>{{rowData.arancptll_tr_jumlah_kantong||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Jenis Darah yang Diperlukan</h4>
                              <p>{{rowData.arancptll_tr_jenis_darah||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Diperlukan</h4>
                              <p>{{rowData.arancptll_tr_tanggal | moment("DD MMMM YYYY")}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Inform Consent</h4>
                              <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arancptll_tr_inform_consent_id','Tindakan Transfusi')" v-if="rowData.arancptll_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                                <i class="icon-file-eye2"></i>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Pasien Pernah Transfusi</h4>
                              <p>{{getConfigDynamic(Config.mr.pernahTidakPernah,rowData['arancptll_tr_pasien_transfusi'])||"-"}}</p>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              </div>
          </div>
        </div>
        
        <div class="row g-3 mt-3" v-if="rowData.arancptll_is_ph == 'Y'">
          <div class="col-lg-12">
              <div class="card shadow-0 border">
                <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">Tindakan Phleubotomy</h6>
                </div>
                <div class="card-body py-2">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal dilakukan</h4>
                              <p>{{rowData.arancptll_ph_tanggal|moment('DD MMMM YYYY')}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Jumlah Kantong</h4>
                              <p>{{rowData.arancptll_ph_jml_kantong||0}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Inform Consent</h4>
                                <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arancptll_ph_inform_consent_id','Tindakan Phlebotomy')" v-if="rowData.arancptll_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                                  <i class="icon-file-eye2"></i>
                                </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </div>

        
        <div class="row g-3 mt-3" v-if="rowData.arancptll_is_hiv == 'Y'">
          <div class="col-lg-12">
            <div class="card shadow-0 border">
              <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">Pemeriksaan HIV</h6>
              </div>
              <div class="card-body py-2">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Tanggal dilakukan</h4>
                            <p>{{rowData.arancptll_hiv_date|moment('DD MMMM YYYY')}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="text-center mt-3">
        <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue mr-1"><i class="icon-plus2 mr-2"></i>Tambah Pemeriksaan
          Laboratorium</a>
        <a href="javascript:;" @click="openTransfusi=true" class="btn alpha-success mr-1"><i class="icon-plus2 mr-2"></i>Tindakan Transfusi</a>
        <a href="javascript:;" @click="openPh=true" class="btn alpha-danger mr-1"><i class="icon-plus2 mr-2"></i>Tindakan
          Phlebotomy</a>
        <a href="javascript:;" @click="openHiv=true" class="btn alpha-success mr-1"><i class="icon-plus2 mr-2"></i>Pemeriksaan HIV
            </a>
        <a href="javascript:;" @click="openTemplatePenunjangData" v-if="isParent.row.is_have_template_lab" class="btn alpha-success mr-1 mt-1"><i class="fas fa-receipt mr-2"></i>Ambil Pemeriksaan Dari Template</a>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
              <label for="">Apakah Pasien Harus Berpuasa? <strong
                  class="text-danger">*</strong></label>
              <b-form-radio-group :options="Config.mr.yesNoOptV2"
              v-model="rowData.arancptll_fasting" />

              <VValidate :name="'Prioritas Pemeriksaan Lab' +' Advis Ke #'+keyData"
              v-model="rowData.arancptll_fasting"
              :rules="toValidate(isParent.mrValidation.arancptll_fasting)" />
          </div>
        </div>
        <div class="col-md-5">
          <div>
            <label for="">Catatan Permintaan</label>
            <b-form-textarea v-model="rowData.arancptll_catatan_permintaan" name="CatatanPermintaan" id="CatatanPermintaan" rows="2"
              class="form-control"></b-form-textarea>
          </div>
        </div>
      </div>

    </div>

    
    <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
        <div class="modal-header d-block p-0 mt-3">
            <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
            <li v-for="(v,k) in rowData.arancptll_data" :key="k" @click="changeTabLab(k)" class="nav-item">
                <a href="javascript:;" data-toggle="tab"
                :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
            </li>
            </ul>
        </div>
        <div class="modal-body p-0 mt-3">
            <div class="tab-content">
            <div v-for="(v,k) in rowData.arancptll_data" :key="k+'labform'"
                :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
                <template v-for="(v1,k1) in (v.dubData||[])">
                <div :key="k1+'sss'">
                    <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                    {{v1.sub}}</div>
                    <div class="row sc-row g-0">
                    <template v-for="(v2,k2) in (v1.data||[])">
                        <div class="col-md-4 col-lg-3" v-if="v2.id" :key="k2+'xdx'">
                            <div class="selection-control">
                            <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                            </b-form-checkbox>
                            <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                            </div>
                        </div>
                    </template>
                    </div>
                </div>
                </template>
            </div>
            </div>
            <div class="row ml-1 mt-2">
            <div class="col-md-8 col-lg-4">
                <div class="form-group row">
                <label>Lainnya</label>
                <b-form-input v-model="rowData.arancptll_data_lainnya" type="text" class="form-control" />
                </div>
            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
            <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
            <span class="m-0" v-if="selectedLabInput">{{selectedLabInput||"-"}}</span>
            <span v-else> - </span>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white">
            <div class="text-right">
            <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
            </div>
        </div>
    </b-modal>

    <validation-observer ref="VFormTransfusi">

      <b-modal v-model="openTransfusi" ok-title="Submit" cancel-title="Batalkan" @cancel.prevent="batalTransfusi()" @ok.prevent="doSubmitTransfusi" :title="'Tindakan Tranfusi'" size="lg">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Pasien Pernah Transfusi <strong class="text-danger">*</strong></label>
              <b-form-radio-group
              :options="Config.mr.pernahTidakPernah"
              v-model="rowData['arancptll_tr_pasien_transfusi']" />
            </div>
            <VValidate :name="'Pasien Pernah Transfusi' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_pasien_transfusi"
                :rules="toValidate(isParent.mrValidation.arancptll_tr_pasien_transfusi)" />
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Indikasi Transfusi <strong class="text-danger">*</strong></label>
              <b-form-input v-model="rowData.arancptll_tr_indikasi_transfusi" class="form-control" />
              <VValidate :name="'Indikasi Transfusi' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_indikasi_transfusi"
                :rules="toValidate(isParent.mrValidation.arancptll_tr_indikasi_transfusi)" />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label>Golongan Darah <strong class="text-danger">*</strong></label>
              <b-form-input v-model="rowData.arancptll_tr_golongan_darah" class="form-control" />
              <VValidate :name="'Golongan Darah' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_golongan_darah"
                :rules="toValidate(isParent.mrValidation.arancptll_tr_golongan_darah)" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Jumlah Kantong <strong class="text-danger">*</strong></label>
              <b-form-input :disabled="rowData.arancp_is_draft == 'N' && rowData.arancptll_is_transfusi == 'Y' ?true:false" :formatter="number" v-model="rowData.arancptll_tr_jumlah_kantong" class="form-control" />
              <VValidate :name="'Jumlah Kantong' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_jumlah_kantong"
                :rules="toValidate(isParent.mrValidation.arancptll_tr_jumlah_kantong)" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Jenis Darah yang Diperlukan <strong class="text-danger">*</strong></label>
              <v-select placeholder="Jenis Darah" v-model="rowData.arancptll_tr_jenis_darah"
                :options="Config.mr.JenisDarah" label="text" :clearable="true"
                :reduce="v=>v.value"></v-select>
              <VValidate :name="'Jenis Darah yang Diperlukan' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_jenis_darah"
                :rules="toValidate(isParent.mrValidation.arancptll_tr_jenis_darah)" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="">Tanggal Diperlukan <strong class="text-danger">*</strong></label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                </div>
                <datepicker input-class="form-control transparent"
                placeholder="Pilih Tanggal" class="my-datepicker"
                calendar-class="my-datepicker_calendar" v-model="rowData.arancptll_tr_tanggal">
                </datepicker>
              </div>
              <VValidate :name="'Tanggal Diperlukan' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_tanggal"
                :rules="toValidate(isParent.mrValidation.arancptll_tr_tanggal)" />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="">Inform Consent <strong class="text-danger">*</strong></label>
              <div>
                <a href="javascript:;" @click="openInformConsentSingle(rowData,'arancptll_tr_inform_consent_id','Tindakan Transfusi')" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo mr-1" v-b-tooltip.hover title="Tambah Informed Consent">
                  <i class="icon-file-check2"></i>
                </a>
                <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arancptll_tr_inform_consent_id','Tindakan Transfusi')" v-if="rowData.arancptll_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1" v-b-tooltip.hover title="Lihat Informed Consent">
                  <i class="icon-file-eye2"></i>
                </a>
                <a href="javascript:;" @click="deleteInformConsentSingle(rowData,'arancptll_tr_inform_consent_id')" v-if="rowData.arancptll_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Consent">
                  <i class="icon-bin"></i>
                </a>
              </div>
              
              <VValidate :name="'Informed Consent Tindakan Tranfusi' +' Advis Ke #'+keyData"
                v-model="rowData.arancptll_tr_inform_consent_id"
                :rules="{required: 1}" />
            </div>
          </div>

          <!--
          <div class="col-md-3">
            <div class="form-group">
              <label for="">Form Permintaan Darah CITO</label>
              <div>
                <a href="javascript:;" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo" 
                v-b-tooltip.hover title="Form Permintaan CITO">
                  <i class="icon-download"></i>
                </a>
              </div>
            </div>
          </div>
          -->
        </div>
      </b-modal>
    </validation-observer>

    <validation-observer ref="VFormPH">
    <b-modal v-model="openPh" ok-title="Submit" cancel-title="Batalkan" @cancel.prevent="batalPH()" @ok.prevent="doSubmitPH" :title="'Tindakan Phlebotomy'" size="md">
    
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Tanggal dilakukan <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
              </div>
              <datepicker input-class="form-control transparent"
              placeholder="Pilih Tanggal" class="my-datepicker"
              calendar-class="my-datepicker_calendar" v-model="rowData.arancptll_ph_tanggal">
              </datepicker>
            </div>
            
            <VValidate :name="'Tanggal Dilakukan' +' Advis Ke #'+keyData"
              v-model="rowData.arancptll_ph_tanggal"
              :rules="toValidate(isParent.mrValidation.arancptll_ph_tanggal)" />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Jumlah Kantong <strong class="text-danger">*</strong></label>
            <b-form-input :formatter="number" v-model="rowData.arancptll_ph_jml_kantong" class="form-control" />
            <VValidate :name="'Jumlah Kantong Diperlukan' +' Advis Ke #'+keyData"
              v-model="rowData.arancptll_ph_jml_kantong"
              :rules="toValidate(isParent.mrValidation.arancptll_ph_jml_kantong)" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Inform Consent <strong class="text-danger">*</strong></label>
            <div>
              <a href="javascript:;" @click="openInformConsentSingle(rowData,'arancptll_ph_inform_consent_id','Tindakan Phlebotomy')" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo mr-1" v-b-tooltip.hover title="Tambah Informed Consent">
                <i class="icon-file-check2"></i>
              </a>
              <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arancptll_ph_inform_consent_id','Tindakan Phlebotomy')" v-if="rowData.arancptll_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1" v-b-tooltip.hover title="Lihat Informed Consent">
                <i class="icon-file-eye2"></i>
              </a>
              <a href="javascript:;" @click="deleteInformConsentSingle(rowData,'arancptll_ph_inform_consent_id')" v-if="rowData.arancptll_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Consent">
                <i class="icon-bin"></i>
              </a>
            </div>
          
            <VValidate :name="'Informed Consent Tindakan Phlebotomy' +' Advis Ke #'+keyData"
              v-model="rowData.arancptll_ph_inform_consent_id"
              :rules="{required: 1}" />
          </div>
        </div>
      </div>
    </b-modal>
    </validation-observer>

    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcentSingle">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien` +' Advis Ke #'+keyData" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada` +' Advis Ke #'+keyData" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>

    <validation-observer ref="VFormHIV">
    <b-modal v-model="openHiv" ok-title="Submit" cancel-title="Batalkan" @cancel.prevent="batalHIV()" @ok.prevent="doSubmitHIV" :title="'Tindakan HIV'" size="sm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Tanggal dilakukan</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
              </div>
              <datepicker input-class="form-control transparent"
              placeholder="Pilih Tanggal" class="my-datepicker"
              calendar-class="my-datepicker_calendar" v-model="rowData.arancptll_hiv_date">
              </datepicker>
            </div>
            
            <VValidate :name="'Tanggal Dilakukan'"
              v-model="rowData.arancptll_hiv_date"
              :rules="{required: 1}" />
          </div>
        </div>
      </div>
    </b-modal>
    </validation-observer>

    
    <!-- PENUNJANG -->
    <validation-observer ref="VFormTemplate">
    <b-modal v-model="openTemplatePenunjang" @ok.prevent="submitTemplatePenunjang" :title="'Template Penunjang'" size="md" ok-title="Pilih Template">
    <div class="row">
        <div class="col-md-12">
        <template v-if="templatePenunjang">
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
            <tr>
                <th width="20"></th>
                <th>Nama</th>
                <th>Isi pemeriksaan</th>
                <th width="80">Aksi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v,k) in (templatePenunjang||[])" :key="k">
                <td>
                <b-form-checkbox :disabled="(v.mtp_is_lab == 'Y' && v.mtp_is_radio == 'Y') && rowData.arancsa_is_radiologi != 'Y'" v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                </td>
                <td>
                  {{v.mtp_name}}
                  <small v-if="(v.mtp_is_lab == 'Y' && v.mtp_is_radio == 'Y')" class="text-info d-block">*Silakan centang tindak lanjut laboratorium & Radiologi untuk bisa memilih template ini.</small>
                </td>
                <td>
                <span v-if="v.mtp_is_lab == 'Y'" class="badge bg-success mb-1 d-block">Laboratorium</span>
                <span v-if="v.mtp_is_radio == 'Y'" class="badge bg-primary mb-1 d-block">Radiologi</span>
                </td>
                <td>
                    <a @click="openDetailPenunjang(k,v.data_lab,v.data_radio)"
                                        href="javascript:;"
                                        title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
                                        ><i class="icon-eye"></i></a>
                </td>
            </tr>
            <tr v-if="!(templatePenunjang||[]).length">
                <td colspan="4" class="text-center">Tidak ada data</td>
            </tr>
            </tbody>
            </table>
        </template>
        <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
        </div>
    </div>
    </b-modal>
    </validation-observer>

    <b-modal v-model="openDetailIsiPenunjang" @ok.prevent="selectOneTemplatePenunjang()" :title="'Detail Isi Template'" size="md" ok-title="Pilih Template">
    <div class="row">
        <div class="col-md-12">
        <table class="table table-bordered table-sm">
            <thead>
                <tr>
                <th>Nama Tindakan</th>
                <th>Tipe Tindakan</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in (dataDetailPenunjangLab||[])" :key="k+'lab'">
                <td>{{v||"-"}}</td>
                <td>
                <span class="badge bg-success mb-1">Laboratorium</span>
                </td>
                </tr>
                <tr v-for="(v,k) in (dataDetailPenunjangRadiologi||[])" :key="k+'rad'">
                <td>{{v||"-"}}</td>
                <td>
                <span class="badge bg-primary mb-1">Radiologi</span>
                </td>
                </tr>
                <tr v-if="!(dataDetailPenunjangLab||[]).length && !(dataDetailPenunjangRadiologi||[]).length">
                <td class="text-center">Tidak ada data</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
    </b-modal>

  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'


export default{
  extends: GlobalVue,
  components: {
    Datepicker
  },
  props: {
    rowData: Object,
    keyData: Number,
  },
  data() {
    return {
      openLab: false,
      openTransfusi: false,
      openPh: false,
      activeTabLab: 0,
      rowIC: {
        aranic_is_pasien_setuju: null,
        aranic_persetujuan_tindakan: null
      },
      openModalIC: false,
      openModalICDetail: false,
      openHiv: false,

      templatePenunjang: false,
      openTemplatePenunjang: false,
      dataDetailPenunjangLab: [],
      dataDetailPenunjangRadiologi: [],
      openDetailIsiPenunjang: false,
      selectedDetailPenunjang: null,
    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    selectedLabInput() {
      let data = []
      for (let ik = 0; ik < (this.rowData.arancptll_data || []).length; ik++) {
          for (let jk = 0; jk < (this.rowData.arancptll_data[ik]['dubData'] || []).length; jk++) {
              for (let kk = 0; kk < (this.rowData.arancptll_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                  if (this.rowData.arancptll_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                      data.push(this.rowData.arancptll_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
          }
      }
      if (this.rowData.arancptll_data_lainnya) {
          data.push(this.rowData.arancptll_data_lainnya)
      }
      return data.join(", ")
    },
    now(){
      return moment().format('DD MMM YYYY, HH:mm:ss')
    },

    selectedTemplateLab(){
      let selected = []
      for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
          if(this.templatePenunjang[i]['checked']){
          for (let j = 0; j < (this.templatePenunjang[i]['mtp_lab_selected_id']||[]).length; j++) {
              let dataSelected = this.templatePenunjang[i]['mtp_lab_selected_id'][j]
              selected.push(dataSelected)
          }
          }
      }
      return selected
    },
    selectedTemplateLabLainnya(){
        let selected = []
        for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
            if(this.templatePenunjang[i]['checked'] && this.templatePenunjang[i]['mtp_lab_temp_lainnya']){
            let dataSelected = this.templatePenunjang[i]['mtp_lab_temp_lainnya']
            selected.push(dataSelected)
            }
        }
        return selected.join(", ")
    },
    selectedTemplateRadiologi(){
        let selected = []
        for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
            if(this.templatePenunjang[i]['checked']){
                for (let j = 0; j < (this.templatePenunjang[i]['mtp_radio_selected_id']||[]).length; j++) {
                    let dataSelected = this.templatePenunjang[i]['mtp_radio_selected_id'][j]
                    selected.push(dataSelected)
                }
            }
        }
        return selected
    },
    selectedTemplateRadiologiLainnya(){
        let selected = []
        for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
            if(this.templatePenunjang[i]['checked'] && this.templatePenunjang[i]['mtp_radio_temp_lainnya']){
                let dataSelected = this.templatePenunjang[i]['mtp_radio_temp_lainnya']
                selected.push(dataSelected)
            }
        }
        return selected.join(", ")
    },

    

  },
  methods: {
    batalHIV(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan membatalkan Tindakan HIV?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.rowData.arancptll_is_hiv = 'N'
          this.rowData.arancptll_hiv_date = null
          
          this.openHiv = false

          this.$swal({
            title: "Tindakan Berhasil Dibatalkan",
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }
      })
    },
    batalTransfusi(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan membatalkan Tindakan Transfusi?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.rowData.arancptll_is_transfusi = 'N'
          this.rowData.arancptll_tr_pasien_transfusi = null
          this.rowData.arancptll_tr_indikasi_transfusi = null
          this.rowData.arancptll_tr_golongan_darah = null
          this.rowData.arancptll_tr_jumlah_kantong = null
          this.rowData.arancptll_tr_jenis_darah = null
          this.rowData.arancptll_tr_tanggal = null
          this.rowData.arancptll_tr_inform_consent_id = null
          this.deleteInformConsentSingle(this.row,'arancptll_tr_inform_consent_id')
          
          this.openTransfusi = false

          this.$swal({
            title: "Tindakan Berhasil Dibatalkan",
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }
      })
    },
    batalPH(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan membatalkan Tindakan Phlebotomy?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.rowData.arancptll_is_ph = 'N'
          this.rowData.arancptll_ph_tanggal = null
          this.rowData.arancptll_ph_jml_kantong = null
          this.rowData.arancptll_ph_inform_consent_id = null

          this.deleteInformConsentSingle(this.row,'arancptll_ph_inform_consent_id')

          this.openPh = false

          this.$swal({
            title: "Tindakan Berhasil Dibatalkan",
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }
      })

    },
    doSubmitTransfusi(){
      this.$refs['VFormTransfusi'].validate().then(success=>{
        if(success){
            this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menambahkan Tindakan Transfusi?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if(result.value){
              this.rowData.arancptll_is_transfusi = 'Y'
              this.$swal({
                title: "Tindakan Berhasil Ditambahkan",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.openTransfusi = false
            }
          })

        } 
      })
    },
    doSubmitPH(){
      this.$refs['VFormPH'].validate().then(success=>{
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menambahkan Tindakan Phlebotomy?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if(result.value){
              this.rowData.arancptll_is_ph = 'Y'
              this.$swal({
                title: "Tindakan Berhasil Ditambahkan",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.openPh = false
            }
          })
        } 
      })
    },
    doSubmitHIV(){
      this.$refs['VFormHIV'].validate().then(success=>{
        if(success){
            this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menambahkan Tindakan HIV?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if(result.value){
              this.rowData.arancptll_is_hiv = 'Y'
              this.$swal({
                title: "Tindakan Berhasil Ditambahkan",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.openHiv = false
            }
          })

        } 
      })
    },
    
    toValidate(val){
      return {...val}
    },
    isShowHeadLab(i) {
      let isData = 0
      for (let j = 0; j < (this.rowData.arancptll_data[i]['dubData'] || []).length; j++) {
          for (let k = 0; k < (this.rowData.arancptll_data[i]['dubData'][j]['data'] || []).length; k++) {
              if (this.rowData.arancptll_data[i]['dubData'][j]['data'][k]['selected']) {
                  isData += 1
              }
          }
      }
      return isData
    },
    isShowHeadSubHeadLab(i, j) {
      let isData = 0
      for (let k = 0; k < (this.rowData.arancptll_data[i]['dubData'][j]['data'] || []).length; k++) {
          if (this.rowData.arancptll_data[i]['dubData'][j]['data'][k]['selected']) {
              isData += 1
          }
      }
      return isData
    },
    isShowLab(i, j, k) {
      let isData = 0
      if (this.rowData.arancptll_data[i]['dubData'][j]['data'][k]['selected']) {
          isData += 1
      }
      return isData
    },
    doOpenLab() {
      this.openLab = true
    },
    changeTabLab(e) {
      this.activeTabLab = e
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },

    deleteInformConsentSingle(row,field){
      Gen.apiRest('/do/' + 'RanapCPPTDokter', {
        data: {
            type: 'remove-ic-single',
            id: row[field],
        }
      }, 'POST').then(res => {
        this.rowData[field] = null
        this.isParent.autoSaveCPPTDokter(this.rowData)        
      })
    },
    
    openInformConsentDetailSingle(row,field,tindakan){
      Gen.apiRest('/do/' + 'RanapCPPTDokter', {
        data: {
            type: 'get-data-ic-single',
            id: row[field],
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row 
        this.rowIC.tindakan = tindakan
        this.rowIC.id_tindakan = row[field]
        this.rowIC.field = field
      
        this.openModalICDetail = true                   
      })
    },
    openInformConsentSingle(row,field,tindakan){

        if(!row[field]){
        
            this.rowIC.aranic_is_pasien_setuju = null
            this.rowIC.aranic_persetujuan_tindakan = null

            this.rowIC.tindakan = tindakan
            this.rowIC.id_tindakan = row[field]
            this.rowIC.field = field


            this.rowIC.aranic_diagnosis = null
            this.rowIC.aranic_id = null
            this.rowIC.aranic_dasar_diagnosis = null
            this.rowIC.aranic_tindakan_dokter = null
            this.rowIC.aranic_indikasi_tindakan = null
            this.rowIC.aranic_tata_cara = null
            this.rowIC.aranic_tujuan = null
            this.rowIC.aranic_risiko = null
            this.rowIC.aranic_kompilasi = null
            this.rowIC.aranic_prognosis = null
            this.rowIC.aranic_alternatif = null
            this.rowIC.aranic_lainnya = null
  
        }else{  
            Gen.apiRest('/do/' + 'RanapCPPTDokter', {
                data: {
                  type: 'get-data-ic-single',
                  id: row[field],
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = tindakan
                this.rowIC.id_tindakan = row[field]  
                this.rowIC.field = field
            })
            // console.log(edit)
        }
        this.openModalIC = true
    },
    submitInformConcentSingle(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false

                let data = this.rowIC
                data.type = 'submit-data-inform-consent-single'
                data.aranic_aranr_id = this.rowData.aranncp_aranr_id
                data.aranic_name = this.rowIC.tindakan
                data.aranic_created_on = 'Laboratorium'

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RanapCPPTDokter', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    this.isParent.autoSaveCPPTDokter(this.rowData)

                    this.rowData[this.rowIC.field] = res.data

                    this.rowIC.aranic_id = null
                    this.rowIC.field = null
                    
                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    },
    
    selectOneTemplatePenunjang(){
      this.templatePenunjang[this.selectedDetailPenunjang]['checked'] = true
      this.submitTemplatePenunjang()
    },
    openTemplatePenunjangData(){
      let idRefTemplate = this.user.levelId == 1 ? this.isParent.rowReg.aranr_dokter_id : this.isParent.row.arancp_created_by
      this.templatePenunjang = false
      let data = {
          id_dokter: idRefTemplate,
          typeTemplate: "LAB",
          type: 'get-template-penunjang'
      }
      Gen.apiRest(
      "/do/" + 'RanapCPPTSBAR', {
          data: data
      },"POST").then(res => {
          this.openTemplatePenunjang = true
          this.templatePenunjang = res.data.data
      })
    },
    openDetailPenunjang(k,data_lab,data_radiologi){
      this.selectedDetailPenunjang = k
      this.dataDetailPenunjangLab = data_lab
      this.dataDetailPenunjangRadiologi = data_radiologi
      this.openDetailIsiPenunjang = true
    },
    submitTemplatePenunjang(){
      if(!(this.selectedTemplateLab||[]).length && !(this.selectedTemplateRadiologi||[]).length){
          return this.$swal({
              icon: 'error',
              title: 'Wajib Memilih Satu Item Template'
          })
      }

      this.openDetailIsiPenunjang = false

      this.$swal({
          html: 'Menyalin Template...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
              this.$swal.showLoading()
          }
      })
      
      let data = {
          selectedTemplateLabLainnya: this.selectedTemplateLabLainnya,
          selectedTemplateLab: this.selectedTemplateLab,
          selectedTemplateRadiologiLainnya: this.selectedTemplateRadiologiLainnya,
          selectedTemplateRadiologi: this.selectedTemplateRadiologi,
          dataMasterLab: this.rowData.arancptll_data,
          dataMasterRadiologi: this.rowData.arancptlr_data,
          type : 'replace-penunjang-from-template'
      }
      Gen.apiRest(
          "/do/" + 'RanapCPPTSBAR', {
          data: data
          },
          "POST"
      ).then(res => {
          this.rowData.arancptll_data = res.data.data_lab
          this.rowData.arancptll_data_lainnya = res.data.data_lab_lainnya
          this.rowData.arancsa_is_lab = res.data.is_lab ? "Y" : this.rowData.arancsa_is_lab
          
          this.rowData.arancptlr_data = res.data.data_radiologi
          this.rowData.arancptlr_data_lainnya = res.data.data_radiologi_lainnya
          this.rowData.arancsa_is_radiologi = res.data.is_radiologi ? "Y" : this.rowData.arancsa_is_radiologi

          this.$nextTick(() => {
              this.$swal().close()
          })
          this.openTemplatePenunjang = false
      })
    },
    
  },
  watch: {
    selectedLabInput(v){
      setTimeout(()=>{
          this.rowData.arancptll_tindakan = v
      },250)
    }
  }
  
}
</script>