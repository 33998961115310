<template>
  <div>
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Konsultasikan ke Dietisien</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="w-100"></div>
        <div class="col-md-8">
          <div class="form-group mb-0">
            <label for="konsulIkhtisar">Ikhtisar Klinik & Pemeriksaan Penunjang <strong class="text-danger">*</strong></label>
            <b-form-textarea v-model="rowData.arancptlln_dietisien_ikhtisar" name="konsulIkhtisar" id="konsulIkhtisar" rows="4"
              class="form-control">
            </b-form-textarea>
            
            <VValidate 
              :name="'Ikhtisar Klinik & Pemeriksaan Penunjang Pemeriksaan Alih Rawat'+' Advis Ke #'+keyData" 
              v-model="rowData.arancptlln_dietisien_ikhtisar" 
              :rules="toValidate(isParent.mrValidation.arancptlln_dietisien_ikhtisar)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  props: {
    rowData: Object,
    keyData: Number,
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
  },     
}

</script>