<template>
  <div>
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Resep</h6>
    </div>
    <div class="card-body p-3" v-if="Object.keys(rowData||{}).length">
      <div class="row">
        <div class="col-md-6">
          <div class="card border mb-0">
            <div class="card-header bg- head_panel_red p-2">
              <h6 class="font-weight-semibold text-dark card-title">Catatan Alergi Pasien</h6>
            </div>
            <div class="card-body py-2 px-3">
              <ul class="mb-0 pl-3" v-if="rowData.arankm_has_alergi == 'Y'">
                <li class="my-1" v-for="(v,k) in (rowData.arankm_alergi||[])" :key="k+'alergi'">
                  <span class="font-weight-semibold">({{v.jenis||"-"}})</span>
                  <span>{{v.name||"-"}}</span>
                </li>
              </ul>
              <span v-else>Tidak ada Alergi</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border mb-0">
            <div class="card-header bg- head_panel_blue p-2">
              <h6 class="font-weight-semibold text-dark card-title">Catatan Obat Rutin Pasien
              </h6>
            </div>
            <div class="card-body py-2 px-3">
              <span class="my-1" v-if="rowData.arankm_has_obat_rutin == 'Y'">{{rowData.arankm_keterangan_obat_rutin||"-"}}</span>
              <span class="my-1" v-else> Tidak ada obat rutin </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped table-sm table-hover table-bordered">
        <thead>
        <tr>
            <th width="48" v-if="!rowData.disabled_farmasi">Aksi</th>
            <th width="160">Jenis</th>
            <th width="320">Nama</th>
            <th width="150">Jumlah</th>
            <th width="210">Frekuensi</th>
            <th>Keterangan</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(v,k) in rowData.resepDokter" :key="'resepDokter'+k">
            <td v-if="!rowData.disabled_farmasi">
            <a href="javascript:;" @click="removeObat(v,k)"
                class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                class="icon-trash"></i></a>
            </td>
            <td>
            <v-select placeholder="Pilih Item" :disabled="rowData.disabled_farmasi" v-model="rowData.resepDokter[k].aranrcp_jenis"
                :options="(user.levelId == uPerawatRanap || user.levelId == uPerawatJagaBangsal) ? Config.mr.configResepPerawatRanap : Config.mr.configResepRanap" label="text"
                @input="changeJenis($event,k)" :reduce="v=>v.value">
            </v-select>
            <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                v-model="rowData.resepDokter[k].aranrcp_jenis" :rules="{required : 1}" />
            </td>
            <td>
            <div class="d-flex">
                <template v-if="rowData.resepDokter[k]['aranrcp_jenis'] == 1">
                <v-select style="flex: 1;" class="med-selection select-paging"
                    placeholder="Pilih Item"
                    :disabled="rowData.disabled_farmasi"
                    :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                    @open="countLen" @search="filterObat($event,k);countLen();"
                    v-model="rowData.resepDokter[k].aranrcp_item_id" :options="rowData.mObatPreferensi"
                    label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                    :reduce="v=>v.value"
                    :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                    <template slot="selected-option">
                    <span
                        :class="isAlergiLabel(v.aranrcp_nama)?'text-warning':''">{{ v.aranrcp_nama }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                        v-if="option.preferensi"><b>{{ option.text }}</b></span>
                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                        v-else>{{ option.text }}</span>
                    </template>
                    <template #list-footer>
                    <li class="list-paging">
                        <div class="d-flex align-items-center justify-content-between">
                        <b-button
                            @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                            <i class="icon-arrow-left22 text-white"></i></b-button>
                        <small>Halaman {{v.currentPage}} dari
                            {{ceilData(v.totalRows/10)}}</small>
                        <b-button
                            @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                            <i class="icon-arrow-right22 text-white"></i></b-button>
                        </div>
                    </li>
                    </template>
                </v-select>
                <br />
                </template>

                <template v-else-if="rowData.resepDokter[k]['aranrcp_jenis'] == 2">
                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                    placeholder="Pilih Item" 
                    :disabled="rowData.disabled_farmasi"
                    v-model="rowData.resepDokter[k].aranrcp_item_id"
                    :options="isParent.mAlatKesehatan" label="text"
                    @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                    :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                </v-select>
                </template>

                <template v-else-if="rowData.resepDokter[k]['aranrcp_jenis'] == 3">
                <v-select style="flex: 1;" class="med-selection select-paging"
                    placeholder="Pilih Item" v-model="rowData.resepDokter[k].aranrcp_item_id"
                    :disabled="rowData.disabled_farmasi"
                    :options="rowData.mRacikan" label="text"
                    @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                    :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                </v-select>
                </template>

                <template v-else-if="rowData.resepDokter[k]['aranrcp_jenis'] == 5">
                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                    placeholder="Pilih Item" 
                    :disabled="rowData.disabled_farmasi"
                    v-model="rowData.resepDokter[k].aranrcp_item_id"
                    :options="isParent.mAlatInfus" label="text"
                    @input="selectJumlahSatuanInfus($event,k)" :clearable="true"
                    :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                </v-select>
                </template>

                <a href="javascript:;" v-if="rowData.resepDokter[k]['aranrcp_jenis'] !== 3 && !rowData.disabled_farmasi"
                @click="rowData.resepDokter[k]['aranrcp_item_id'] = 99999; rowData.resepDokter[k]['aranrcp_nama'] = 'Lainnya'"
                v-b-tooltip.hover="'Pilih Lainnya'"
                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                    class="icon-plus-circle2"></i></a>
            </div>
            <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                v-model="rowData.resepDokter[k]['aranrcp_item_id']" :rules="{required : 1}" />

            <div v-if="rowData.resepDokter[k]['aranrcp_item_id'] == 99999">
                <b-form-input type="text" :disabled="rowData.disabled_farmasi" v-model="rowData.resepDokter[k]['aranrcp_item_lainnya']"
                class="form-control" id="itemCount" />
                <VValidate :name="'Item Lainnya '+(k+1)"
                message="Nama Item Lainnya Harus Diisi"
                v-model="rowData.resepDokter[k]['aranrcp_item_lainnya']" :rules="{required : 1}" />
            </div>

            <a v-if="rowData.resepDokter[k].aranrcp_jenis == 3 && rowData.resepDokter[k]['aranrcp_item_id'] && !rowData.disabled_farmasi"
                href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title=""
                data-toggle="modal" data-target="#modalRacikanDetail"
                class="font-weight-semibold border-bottom"
                data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

            <small class="text-warning"
                v-if="isAlergiLabel(rowData.resepDokter[k].aranrcp_nama) ">Obat ini termasuk dalam
                kategori alergi</small>
            </td>
            <td>
            <div class="input-group">
                <b-form-input :disabled="rowData.disabled_farmasi" type="text" v-model="rowData.resepDokter[k]['aranrcp_jumlah']"
                :formatter="isParent.number" class="form-control" id="itemCount" />
                <div class="input-group-append">
                <span class="input-group-text">{{rowData.resepDokter[k]['aranrcp_satuan']}}</span>
                </div>
            </div>

            <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                v-model="rowData.resepDokter[k]['aranrcp_jumlah']" :rules="{required : 1}" />
            </td>
            <td>
            <span class="d-flex">
                <v-select :disabled="rowData.disabled_farmasi" style="flex: 1;" class="med-selection"
                placeholder="Pilih Frekuensi" v-model="rowData.resepDokter[k].aranrcp_frekuensi"
                :options="isParent.mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                </v-select>
                <a href="javascript:;" v-if="!rowData.disabled_farmasi" @click="rowData.resepDokter[k]['aranrcp_frekuensi'] = 99999"
                v-b-tooltip.hover="'Pilih Lainnya'"
                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                    class="icon-plus-circle2"></i></a>
            </span>
            <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                v-model="rowData.resepDokter[k]['aranrcp_frekuensi']" 
                v-if="rowData.resepDokter[k].aranrcp_jenis != 2"
                :rules="{required : 1}" />

            <div v-if="rowData.resepDokter[k]['aranrcp_frekuensi'] == 99999">
                <b-form-input type="text" :disabled="rowData.disabled_farmasi"
                v-model="rowData.resepDokter[k]['aranrcp_frekuensi_lainnya']" class="form-control"
                id="itemCount" />
                <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                message="Frekuensi Lainnya Harus Diisi"
                v-if="rowData.resepDokter[k].aranrcp_jenis != 2"
                v-model="rowData.resepDokter[k]['aranrcp_frekuensi_lainnya']"
                :rules="{required : 1}" />
            </div>
            </td>
            <td>
            <b-textarea :disabled="rowData.disabled_farmasi" v-model="rowData.resepDokter[k].aranrcp_keterangan" name="itemNotes"
                id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                class="form-control"></b-textarea>
            </td>
        </tr>
        </tbody>
        <tfoot v-if="!rowData.disabled_farmasi">
        <tr>
            <td class="text-center" colspan="99">
            <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                data-toggle="modal" data-target="#modalResep">
                <i class="icon-plus2 mr-1 align-middle"></i>
                <span class="align-middle">Tambah Resep</span>
            </a>
            
            <a href="javascript:;" @click="openTemplateResep" v-if="isParent.row.is_have_template_resep" class="btn alpha-warning ml-1"
                data-toggle="modal" data-target="#modalResep">
                <i class="fas fa-receipt mr-1 align-middle"></i>
                <span class="align-middle">Ambil Dari Template</span>
            </a>
            </td>
        </tr>
        </tfoot>
    </table>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-8">
          <div>
            <label for="">Catatan Resep</label>
            <b-form-textarea :disabled="rowData.disabled_farmasi"  v-model="rowData.arancptlln_resep_catatan" class="form-control" rows="3"></b-form-textarea>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="openRacikan" :title="'Komposisi Racikan'" size="md" hide-footer>
        <div class="modal-body p-0 table-responsive">
            <p>{{rowRacikan.aranrcp_jumlah||"-"}} {{rowRacikan.aranrcp_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri
            dari:</p>
            <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
                <tr>
                <th width="40">#</th>
                <th>Nama Obat</th>
                <th>Qty</th>
                <th>Frekuensi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in rowRacikan.aranrcp_racikan" :key="k+'rowracik'">
                <td>{{k+1}}</td>
                <td>{{getObat(v.value)||"-"}}</td>
                <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="modal-footer p-0 mt-3">

            <a href="javascript:;" @click="doOpenFormRacikan()" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            <div class="icon-pencil7"></div>
            Ubah Komposisi Racikan
            </a>
            <button type="button" @click="openRacikan = false" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
        </div>
    </b-modal>
    <validation-observer ref="VFormRacikan">
    <b-form @submit.prevent="doSubmitRacikan">
        <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
        <div class="modal-body p-0">
            <div class="alert alert-info">
            <span>Saat ini Anda sedang melakukan perubahan komposisi racikan
                <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik
                <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
            </div>
            <div class="form-group">
            <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in rowRacikan.aranrcp_racikan" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                    <v-select placeholder="Pilih Obat" @input="selectObat($event,k)"
                        v-model="rowRacikan.aranrcp_racikan[k]['value']" :options="getMasterObat(rowRacikan.aranrcp_racikan,k)"
                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                    <VValidate :name="'Obat #'+(k+1)" message="Obat Harus Diisi"
                        v-model="rowRacikan.aranrcp_racikan[k]['value']" :rules="{required : 1}" />
                    </td>
                    <td>
                    <div class="input-group">
                        <input v-model="rowRacikan.aranrcp_racikan[k]['dosis']" type="text" class="form-control"
                        placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;"
                            v-model="rowRacikan.aranrcp_racikan[k]['satuan_dosis']" type="text" class="form-control"
                            placeholder="cth. Kaplet"></div>
                    </div>
                    </td>
                    <td>
                    <div class="input-group">
                        <b-form-input type="text" v-model="rowRacikan.aranrcp_racikan[k]['jumlah']"
                        :formatter="isParent.number" class="form-control" placeholder="cth. 5" />
                        <div class="input-group-append"><span
                            class="input-group-text">{{rowRacikan.aranrcp_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                    </div>

                    <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                        v-model="rowRacikan.aranrcp_racikan[k]['jumlah']" :rules="{required: 1}" />
                    </td>
                    <td>
                    <a href="javascript:;" @click="rowRacikan.aranrcp_racikan.splice(k,1)"
                        class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                        data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="9" class="text-center">
                    <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                    </a>
                    </td>
                </tr>
                </tfoot>
            </table>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
            <div>
                <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                <b-form-input type="text" :formatter="isParent.number" v-model="rowRacikan.aranrcp_jumlah"
                    class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan" />
                <div class="input-group-append">
                    <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.aranrcp_satuan"
                    :options="isParent.mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
                </div>
                <VValidate name="Jumlah" v-model="rowRacikan.aranrcp_jumlah" :rules="{required : 1}" />
                <VValidate name="Satuan" v-model="rowRacikan.aranrcp_satuan" :rules="{required : 1}" />
            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            Batal
            </a>
            <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
        </div>
        </b-modal>
    </b-form>
    </validation-observer>

    <validation-observer ref="VFormTemplate">
    <b-modal v-model="openTemplate" @ok.prevent="submitTemplate" :title="'Template Resep'" size="md" ok-title="Pilih Template">
      <div class="row">
        <div class="col-md-12">
          <template v-if="templateResep">
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
              <tr>
                <th width="20"></th>
                <th>Nama</th>
                <th>Rincian</th>
                <th width="80">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (templateResep||[])" :key="k">
                <td>
                  <b-form-checkbox v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                </td>
                <td>{{v.mtr_name}}</td>
                <td>
                  <span class="d-block font-weight-bold" v-if="v.mtr_total_obat">Total Obat : {{v.mtr_total_obat}}</span>
                  <span class="d-block font-weight-bold" v-if="v.mtr_total_alkes">Total Alkes : {{v.mtr_total_alkes}}</span>
                  <span class="d-block font-weight-bold" v-if="v.mtr_total_infus">Total Infus : {{v.mtr_total_infus}}</span>
                </td>
                <td>
                    <a @click="openDetail(k,v.data)"
										href="javascript:;"
										title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
										><i class="icon-eye"></i></a>
                </td>
              </tr>
              <tr v-if="!(templateResep||[]).length">
                <td colspan="4" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
            </table>
          </template>
          <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
        </div>
      </div>
    </b-modal>
    </validation-observer>

    <b-modal v-model="openDetailIsi" :title="'Detail Resep'" size="lg" ok-title="Pilih Template" @ok.prevent="selectOneTemplate()">
      <div class="row">
        <div class="col-md-12">
           <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Jenis</th>
                  <th>Nama</th>
                  <th>Jumlah</th>
                  <th>Frekuensi</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (dataDetail||[])" :key="k">
                  <td>{{getConfigDynamic(Config.mr.configResep,v.aranrcp_jenis)||"-"}}</td>
                  <td>{{v.aranrcp_nama||"-"}}</td>
                  <td>{{v.aranrcp_jumlah||"-"}} {{v.aranrcp_satuan}}</td>
                  <td>{{v.mdo_name||v.aranrcp_frekuensi_lainnya||"-"}}</td>
                  <td>{{v.aranrcp_keterangan||"-"}}</td>
                </tr>
                <tr v-if="!(dataDetail||[]).length">
                  <td colspan="5" class="text-center">Tidak ada data</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

const moment = require('moment')
import { stringSimilarity } from "string-similarity-js"

export default {
  props: {
    rowData: Object,
    keyData: Number,
  },
  extends: GlobalVue,
  data() {
    return {
      openFormRacikan: false,
      obatLen: 0,
      currentPage: 1,
      openRacikan: false,
      rowRacikan: {},

      openRiwayatObat: false,
      selectedObat: '',
      searchObat: '',
      
      templateResep: false,
      openTemplate: false,
      openTemplateDetail: false,
      dataDetail: [],
      openDetailIsi: false,
      selectedDetail: null,

    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    selectedTemplate(){
      let selected = []
      for (let i = 0; i < (this.templateResep||[]).length; i++) {
        if(this.templateResep[i]['checked']){
          for (let j = 0; j < (this.templateResep[i]['data']||[]).length; j++) {
            let dataSelected = this.templateResep[i]['data'][j]
            dataSelected.aranrcp_code = null
            selected.push(dataSelected)
          }
        }
      }
      return selected
    }
  },
  mounted(){
    console.log(this.isParent.mObat)
    console.log(this.isParent.number)
  },
  methods: {
    
    getObat(val) {
      let index = this.isParent.mObat.findIndex(x => x.value == val)
      if (index !== -1) {
          return this.isParent.mObat[index]['text']
      } else {
          return null
      }
    },
    
    getMasterObat(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
          return k2 != k
      }), v2 => {
          return v2.value
      })
          return _.filter(this.isParent.mObat, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
      })
    },

    changePageObat(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this.rowData[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this.rowData[col][k]['totalRows'] / 10)) {
                page += 1
                this.rowData[col][k]['currentPage'] += 1
            }
        }

        let data = {
            type: 'select-paging-obat',
            page: page,
            idDokter: this.rowData.arancsa_dpjp,
            search: this.rowData[col][k]['search']
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            this.rowData.mObatPreferensi = res.data.data
        })
    },

    openObat(v = null) {
        let data = {
            aranrcp_aranr_id: this.rowData.arancp_aranr_id,
            aranrcp_arancp_id: this.rowData.arancp_id,
            aranrcp_arancsa_id: this.rowData.arancsa_id,
            aranrcp_jenis: 2,
            aranrcp_item_id: null,
            aranrcp_nama: null,
            aranrcp_jumlah: null,
            aranrcp_satuan: null,
            aranrcp_frekuensi: null,
            aranrcp_frekuensi_lainnya: null,
            aranrcp_keterangan: null,
            aranrcp_code : null,
            aranrcp_is_alergi: null,
            aranrcp_signa1: null,
            aranrcp_signa2: null,
            id: null
        }
        if (!this.rowData.isEdit) {
            data.type = 'add-resep-dokter'
            Gen.apiRest(
                "/do/" + 'RanapCPPTDokter', {
                data: data
                },
                "POST"
            ).then(res => {
                this.rowData.resepDokter.push(res.data.row)
            })
        } else {
            this.rowData.resepDokter.push(data)
        }

    },
    
    removeObat(v, k) {
        if (!this.rowData.isEdit) {
            let data = {
                type: 'remove-resep-dokter',
                id: v.aranrcp_id
            }
            Gen.apiRest(
                "/do/" + 'RanapCPPTDokter', {
                data: data
                },
                "POST"
            ).then(res => {
                this.rowData.resepDokter.splice(k, 1)
            })
        } else {
            this.rowData.resepDokter.splice(k, 1)
        }
    },
    
    resetObat() {
        this.loadingOverlay = true
        let data = {
            id: this.rowData.aranrcp_id,
            advis_id : this.rowData.aranrcp_arancsa_id,
            type: 'get-resep-dokter'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            let resp = res.data
            this.rowData.resepDokter = resp.data
            this.loadingOverlay = false
        })
    },

    
    toRacikan(v) {
        this.loadingOverlay = true
        let data = {
            id: v.aranrcp_item_id,
            type: 'get-racikan-data'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            this.isParent.mObat = res.data.mObat
            this.openRacikan = true
        })
    },
    
    doSubmitRacikan() {
      this.$refs['VFormRacikan'].validate().then(success => {
        if (success) {
          let data = this.rowRacikan
          let idx = this.rowData.resepDokter.findIndex(x => x.aranrcp_id == this.rowRacikan.aranrcp_id)
          this.rowData.resepDokter[idx]['aranrcp_satuan'] = this.rowRacikan.aranrcp_satuan
          this.rowData.resepDokter[idx]['aranrcp_jumlah'] = this.rowRacikan.aranrcp_jumlah
          this.rowData.resepDokter[idx]['aranrcp_racikan'] = this.rowRacikan.aranrcp_racikan
          this.openFormRacikan = false
        } else {
          setTimeout(() => {
          let inv = []
          let el = document.querySelectorAll('.label_error')
          for (let i = 0; i < (el || []).length; i++) {
              if (el[i].style.display !== 'none') {
              inv.push(el[i].id)
              }
          }
          if (inv.length) document.getElementById(inv[0]).scrollIntoView({
              behavior: 'smooth',
              block: 'center'
          })
          }, 500)
        }
      })
    },
    
    doOpenFormRacikan() {
        let data = {
            type: 'get-all-obat'
        }
        Gen.apiRest(
            "/do/" + 'RanapCPPTDokter', {
                data: data
            },
            "POST"
        ).then(res => {
            this.isParent.mObat = res.data.data
            this.openFormRacikan = true
            this.openRacikan = false
        })
    },
    
    addObat() {
        this.rowRacikan.aranrcp_racikan.push({
            value: null,
            dosis: null,
            satuan_dosis: null,
            jumlah: null,
            satuan_jumlah: null,
        })
    },
    
    resetData() {
        this.searchObat = ''
        this.openFormRacikan = false
        this.isParent.apiGet()
    },

    
    changeJenis(e, k) {
        this.rowData.resepDokter[k].aranrcp_item_id = null
        this.rowData.resepDokter[k].aranrcp_frekuensi = null
        this.rowData.resepDokter[k].aranrcp_frekuensi_lainnya = null
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    selectObat(e, k) {
        let index = this.isParent.mObat.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.isParent.mObat[index]
            this.rowRacikan.aranrcp_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.aranrcp_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.aranrcp_racikan[k]['satuan_jumlah'] = data.satuan
        }
    },
    filterObat: _.debounce(function (e, k) {
        if (e) {
            this.rowData.resepDokter[k]['search'] = e
            let id = []
            for (let i = 0; i < (this.rowData.resepDokter || []).length; i++) {
                if (this.rowData.resepDokter[i]['aranrcp_item_id'] && this.rowData.resepDokter[i]['aranrcp_jenis'] == 1) {
                id.push(this.rowData.resepDokter[i]['aranrcp_item_id'])
                }
            }
            Gen.apiRest('/do/' + 'RanapCPPTDokter', {
                data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.rowData.mObatPreferensi = res.data.data
                this.rowData.resepDokter[k]['currentPage'] = 1
                this.rowData.resepDokter[k]['totalRows'] = res.data.totalRows
            })
        }
    }, 10),

    
    selectJumlahSatuan(e, k) {
        let data = {
            id: e,
            type: 'get-by-obat'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            this.rowData.resepDokter[k].aranrcp_satuan = res.data.satuan
            this.rowData.resepDokter[k].aranrcp_nama = res.data.nama
            this.rowData.resepDokter[k].aranrcp_racikan = null

            this.rowData.resepDokter[k].aranrcp_dosis = res.data.dosis
            this.rowData.resepDokter[k].aranrcp_satuan_dosis = res.data.satuan_dosis
            this.rowData.resepDokter[k].aranrcp_item_lainnya = null

            this.isAlergi(res.data.nama, k)
        })
    },
    
    isAlergi(nama, k) {
        let data = []
        for (let i = 0; i < (this.rowData.ausd_alergi || []).length; i++) {
            if (this.rowData.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.rowData.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }

        this.rowData.resepDokter[k]['aranrcp_is_alergi'] = isWarning ? "Y" : "N"
    },
    selectJumlahSatuanAlkes(e, k) {
        let data = {
            id: e,
            type: 'get-by-alkes'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            this.rowData.resepDokter[k].aranrcp_satuan = res.data.satuan
            this.rowData.resepDokter[k].aranrcp_nama = res.data.nama
            this.rowData.resepDokter[k].aranrcp_racikan = null

            this.rowData.resepDokter[k].aranrcp_dosis = null
            this.rowData.resepDokter[k].aranrcp_satuan_dosis = null
            this.rowData.resepDokter[k].aranrcp_frekuensi = null
            this.rowData.resepDokter[k].aranrcp_frekuensi_lainnya = null
            this.rowData.resepDokter[k].aranrcp_item_lainnya = null
        })
    },
    selectJumlahSatuanInfus(e, k) {
        let data = {
            id: e,
            type: 'get-by-infus'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            this.rowData.resepDokter[k].aranrcp_satuan = res.data.satuan
            this.rowData.resepDokter[k].aranrcp_nama = res.data.nama
            this.rowData.resepDokter[k].aranrcp_racikan = null

            this.rowData.resepDokter[k].aranrcp_dosis = null
            this.rowData.resepDokter[k].aranrcp_satuan_dosis = null
            this.rowData.resepDokter[k].aranrcp_frekuensi = null
            this.rowData.resepDokter[k].aranrcp_frekuensi_lainnya = null
            this.rowData.resepDokter[k].aranrcp_item_lainnya = null
        })
    },
    selectJumlahSatuanRacikan(e, k) {
        let data = {
            id: e,
            type: 'get-by-racikan'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
            data: data
        },
        "POST"
        ).then(res => {
            this.rowData.resepDokter[k].aranrcp_satuan = res.data.satuan
            this.rowData.resepDokter[k].aranrcp_nama = res.data.nama
            this.rowData.resepDokter[k].aranrcp_racikan = res.data.data

            this.rowData.resepDokter[k].aranrcp_dosis = null
            this.rowData.resepDokter[k].aranrcp_satuan_dosis = null
            this.rowData.resepDokter[k].aranrcp_frekuensi = null
            this.rowData.resepDokter[k].aranrcp_frekuensi_lainnya = null
            this.rowData.resepDokter[k].aranrcp_item_lainnya = null
        })
    },
    isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.rowData.ausd_alergi || []).length; i++) {
            if (this.rowData.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.rowData.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }
        return isWarning
    },
    countLen() {
      setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
    
    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      if (!this.isParent.row.isEdit) {
        Gen.apiRest(
            "/do/" + 'RanapCPPTDokter', {
            data: dataPost
            },
            "POST"
        )
      }
    }, 1000),


    openTemplateResep(){
      let idRefTemplate = this.user.levelId == 1 ? this.isParent.rowReg.aranr_dokter_id : this.isParent.row.arancp_created_by
      this.templateResep = false
      let data = {
          id_dokter: idRefTemplate,
          type: 'get-template-resep'
      }
      Gen.apiRest(
      "/do/" + 'RanapCPPTSBAR', {
          data: data
      },"POST").then(res => {
          this.openTemplate = true
          this.templateResep = res.data.data
      })
      // kalo super admin biar bisa ngetrack pake template dpjp
    },
    openDetail(k,data){
      this.selectedDetail = k
      this.dataDetail = data
      this.openDetailIsi = true
    },
    selectOneTemplate(){
        this.templateResep[this.selectedDetail]['checked'] = true
        this.submitTemplate()
        this.openDetailIsi = false
    },
    submitTemplate(){
      if(!(this.selectedTemplate||[]).length){
        return this.$swal({
          icon: 'error',
          title: 'Wajib Memilih Satu Item Template'
        })
      }
      let dataPost = this.selectedTemplate
      for (let i = 0; i < (dataPost||[]).length; i++) {
        dataPost[i]['aranrcp_code'] = null
        dataPost[i]['aranrcp_aranr_id'] = this.isParent.row.arancp_aranr_id
        dataPost[i]['aranrcp_arancp_id'] = this.isParent.row.arancp_id
        dataPost[i]['aranrcp_arancsa_id'] = this.rowData.arancsa_id

        dataPost[i]['aranrcp_is_alergi'] = null
        dataPost[i]['aranrcp_signa1'] = null
        dataPost[i]['aranrcp_signa2'] = null
      }

      this.$swal({
          html: 'Menyalin resep...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
              this.$swal.showLoading()
          }
      })
      
      if (!this.isParent.row.isEdit) {
        let data = {
          dataPost : dataPost,
          arancp_id : this.isParent.row.arancp_id,
          arancsa_id : this.rowData.arancsa_id,
          type : 'replace-resep-from-template'
        }
        Gen.apiRest(
            "/do/" + 'RanapCPPTSBAR', {
            data: data
            },
            "POST"
        ).then(res => {
            this.rowData.resepDokter = res.data.data
            this.$nextTick(() => {
              this.$swal().close()
            })
            this.openTemplate = false
        })
      } else {
        this.rowData.resepDokter = dataPost
        this.$nextTick(() => {
          this.$swal().close()
        })
        this.openTemplate = false
      }
    }

      
  },
  watch: {
    'rowData.resepDokter': {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    }
  }
}
</script>