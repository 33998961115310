<template>
  <div>
    
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Pindah Ruang</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Apakah Pasien Berasal Dari Ruang UBS?</label>
            <b-form-radio-group
              v-model="rowData.aranctlpr_from_ubs"
              :options="optionFromUBS"
              name="radio-inline"
            ></b-form-radio-group>
            <VValidate 
              name="Berasal Ruang UBS"
              :vid="'berasal_ubs' + keyData"
              v-model="rowData.aranctlpr_from_ubs" 
              :rules="toValidate(isParent.mrValidation.aranctlpr_from_ubs)"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Pilih Jenis Rawat Inap <strong class="text-danger">*</strong></label>
            <v-select 
              :options="isParent.mJenisRawatInap"
              v-model="rowData.aranctlpr_jenis_ranap"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
          
            <VValidate 
                :name="'Jenis Rawat Inap'+' Advis Ke #'+keyData" 
                v-model="rowData.aranctlpr_jenis_ranap" 
                :rules="toValidate(isParent.mrValidation.aranctlpr_jenis_ranap)"
            />
          </div>
        </div>
        <div class="w-100"></div>
        
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Pilih Kelas</label>
            <v-select 
              :options="isParent.mRanapKelas"
              v-model="rowData.aranctlpr_kelas"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
            <!-- 
            <VValidate 
                :name="'Kelas'+' Advis Ke #'+keyData" 
                v-model="rowData.aranctlpr_kelas" 
                :rules="toValidate(isParent.mrValidation.aranctlpr_kelas)"
            />
            -->
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Pilih Bangsal <strong class="text-danger">*</strong></label>
            <v-select 
              :options="isParent.mRanapBangsal"
              v-model="rowData.aranctlpr_bangsal"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
          
            <VValidate 
                :name="'Bangsal'+' Advis Ke #'+keyData" 
                v-model="rowData.aranctlpr_bangsal" 
                :rules="toValidate(isParent.mrValidation.aranctlpr_bangsal)"
            />
          </div>
        </div>

        
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Pilih Type Pemeriksaan</label>
            <v-select 
              :options="Config.mr.statusLanjutPindahRuangRanap"
              v-model="rowData.aranctlpr_type"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
            <!--
            <VValidate 
                :name="'Tipe Pemeriksaan'+' Advis Ke #'+keyData" 
                v-model="rowData.aranctlpr_type" 
                :rules="toValidate(isParent.mrValidation.aranctlpr_type)"
            />
            -->
          </div>
        </div>

        <template v-if="rowData.aranctlpr_type == 'ICU'">
        <b-col lg="6">        
            <b-form-group class="mt-3">
                <label>Tanda Vital</label>
                <b-form-checkbox-group
                    id="aranctlpr_icu_ttv"
                    class="checkbox-block"
                    v-model="rowData.aranctlpr_icu_ttv"
                    :options="Config.mr.icuTTV"
                    name="aranctlpr_icu_ttv"
                ></b-form-checkbox-group>
            </b-form-group>
            
            <b-form-group class="mt-3">
                <label>Nilai Laboratorium</label>
                <b-form-checkbox-group
                    id="aranctlpr_icu_lab"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_icu_lab"
                    :options="Config.mr.icuNilaiLab"
                    name="aranctlpr_icu_lab"
                ></b-form-checkbox-group>
            </b-form-group>
        </b-col>
        <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Radiografi / Ultrasonografi</label>
                <b-form-checkbox-group
                    id="aranctlpr_icu_radiologi"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_icu_radiologi"
                    :options="Config.mr.icuNilaiRadiologi"
                    name="aranctlpr_icu_radiologi"
                ></b-form-checkbox-group>
            </b-form-group>
            
            <b-form-group class="mt-3">
                <label>Pemeriksaan Fisisk (Onset Akut )</label>
                <b-form-checkbox-group
                    id="aranctlpr_icu_pemeriksaan_fisik"
                    class="checkbox-block"                                      
                    v-model="rowData.aranctlpr_icu_pemeriksaan_fisik"
                    :options="Config.mr.icuPemeriksaanFisik"
                    name="aranctlpr_icu_pemeriksaan_fisik"
                ></b-form-checkbox-group>
            </b-form-group>
        </b-col>          
        </template>

        <template v-else-if="rowData.aranctlpr_type == 'NICU'">
        <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Tanda Vital</label>
                <b-form-checkbox-group
                    id="aranctlpr_picu_ttv"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_picu_ttv"
                    :options="Config.mr.nicuNilaiTTV"
                    name="aranctlpr_picu_ttv"
                ></b-form-checkbox-group>
            </b-form-group>

            <b-form-group class="mt-3">
                <label>Pemeriksaan Radiografi</label>
                <b-form-checkbox-group
                    id="aranctlpr_picu_radiografi"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_picu_radiografi"
                    :options="Config.mr.nicuNilaiRadiografi"
                    name="aranctlpr_picu_radiografi"
                ></b-form-checkbox-group>
            </b-form-group>
        </b-col>
        </template>

        <template v-else-if="rowData.aranctlpr_type == 'AIRBORNE'">
          <b-col lg="6">            
            <b-form-group>
              <label>Kriteria Pasien Masuk Airborne</label>
              <b-form-radio v-for="(v,k) in Config.mr.statusLanjutAirborne" :key="k" v-model="rowData.aranctlpr_airborne" name="airborne" :value="v.value">
                <strong class="text-uppercase">{{v.text||"-"}}</strong>
                <div v-if="v.value == 'SUSPEK TB'" class="criteria-content">
                <ul><li>Klinis</li><li>Radiologis</li></ul>
                </div>

                <div v-if="v.value == 'PENYAKIT LAIN'" class="criteria-content">
                  <b-form-input v-model="rowData.aranctlpr_airborne_text" />
                  <VValidate 
                    v-if="rowData.aranctlpr_airborne == 'PENYAKIT LAIN'"
                    :name="'Penyakit Lain'+' Advis Ke #'+keyData" 
                    v-model="rowData.aranctlpr_airborne_text" 
                    :rules="{required: 1,min: 2, max: 128}"
                  />
                </div>  

              </b-form-radio>
            </b-form-group>
          </b-col>
        </template>

        <template v-else-if="rowData.aranctlpr_type == 'IMUNOSUPRESI'">
          <b-col lg="6">            
            <b-form-group>
              <label>Kriteria Pasien Masuk Imunosupresi</label>

              <b-form-radio v-for="(v,k) in Config.mr.statusLanjutImuno" :key="k" v-model="rowData.aranctlpr_imunosupresi" name="airborne" :value="v.value">
                <strong class="text-uppercase">{{v.text||"-"}}</strong>
                
                <div v-if="v.value == 'GANGGUAN IMUNOLOGIS LAIN'" class="criteria-content">
                  <b-form-input v-model="rowData.aranctlpr_imunosupresi_text" />
                  <VValidate 
                    v-if="rowData.aranctlpr_imunosupresi == 'GANGGUAN IMUNOLOGIS LAIN'"
                    :name="'GANGGUAN IMUNOLOGIS LAIN'+' Advis Ke #'+keyData" 
                    v-model="rowData.aranctlpr_imunosupresi_text" 
                    :rules="{required: 1,min: 2, max: 128}"
                  />
                </div>  

              </b-form-radio>

            </b-form-group>
          </b-col>
        </template>


        <template v-else-if="rowData.aranctlpr_type == 'KICU'">
          <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Tanda Vital</label>
                <b-form-checkbox-group
                    id="aranctlpr_kicu_ttv"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_kicu_ttv"
                    :options="Config.mr.kICUTTV"
                    name="aranctlpr_kicu_ttv"
                ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Pemeriksaan Laboratorium</label>
                <b-form-checkbox-group
                    id="aranctlpr_kicu_nilai_lab"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_kicu_nilai_lab"
                    :options="Config.mr.kICUNilaiLab"
                    name="aranctlpr_kicu_nilai_lab"
                ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </template>

        <template v-else-if="rowData.aranctlpr_type == 'KNICU'">
          <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Kriteria umum</label>
                <b-form-checkbox-group
                    id="aranctlpr_knicu_kriteria_umum"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_knicu_kriteria_umum"
                    :options="Config.mr.kNICUKriteriaUmum"
                    name="aranctlpr_knicu_kriteria_umum"
                ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Tanda Vital</label>
                <b-form-checkbox-group
                    id="aranctlpr_knicu_ttv"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_knicu_ttv"
                    :options="Config.mr.kNICUTTV"
                    name="aranctlpr_knicu_ttv"
                ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </template>

        <template v-else-if="rowData.aranctlpr_type == 'KAIRBORNE'">
          <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Keluar Isolasi Airborne</label>
                <b-form-checkbox-group
                    id="aranctlpr_kairborne"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_kairborne"
                    :options="Config.mr.kAirborne"
                    name="aranctlpr_kairborne"
                ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </template>
        
        <template v-else-if="rowData.aranctlpr_type == 'KIMUNOSUPRESI'">
          <b-col lg="6">            
            <b-form-group class="mt-3">
                <label>Keluar Imunosupresi</label>
                <b-form-checkbox-group
                    id="aranctlpr_kimunosupresi"
                    class="checkbox-block"                    
                    v-model="rowData.aranctlpr_kimunosupresi"
                    :options="Config.mr.kImunoSupresi"
                    name="aranctlpr_kimunosupresi"
                ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </template>
      </div>
    </div>
  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  data(){
    return {
      optionFromUBS: [
        { text: 'Ya', value: 'Y' },
        { text: 'Tidak', value: 'N' },
      ]
    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
  },
  props: {
    rowData: Object,
    keyData: Number,
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
  },     
}

</script>