<template>
  <div>
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Tindakan Operasi</h6>
    </div>
    <div class="card-body p-3">
      
      <div class="row mb-1" v-if="(rencanaOperasi||[]).length && rowData.arancp_is_draft == 'Y'">
        <div class="col-md-7">
            <div class="form-group">
              <label for="tindakanAnestesi">Rencana Operasi<strong
                class="text-danger">*</strong></label>
              <v-select 
                :options="rencanaOperasi"
                v-model="rowData.arancptlo_rencana_operasi_id"
                placeholder="-- Pilih Salah Satu --"
                label="text"
                :clearable="true" :reduce="v=>v.value"
              >
              </v-select>
            </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="tindakanAnestesi" style="opacity: 0;">Rencana Operasi</label>
            <b-button variant="success" @click="salinTindakan"><i class="fas fa-check"></i> Pilih Tindakan</b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Tanggal Operasi <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <datepicker input-class="form-control transparent"
                  placeholder="Tanggal Awal" class="my-datepicker"
                  calendar-class="my-datepicker_calendar"
                  v-model="rowData.arancptlo_tanggal">
              </datepicker>
              <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
              </div>
            </div>
            <VValidate :name="`Tanggal Operasi` +' Advis Ke #'+keyData"
            v-model="rowData.arancptlo_tanggal"
            :rules="toValidate(isParent.mrValidation.arancptlo_tanggal)" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Waktu Operasi <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
              <vue-timepicker manual-input v-model="rowData.arancptlo_waktu" format="HH:mm"  input-class="form-control"></vue-timepicker>
            </div>
            <VValidate :name="`Waktu Operasi` +' Advis Ke #'+keyData"
              v-model="rowData.arancptlo_waktu"
              :rules="toValidate(isParent.mrValidation.arancptlo_waktu)" />
          </div>
          <div class="form-group ml-2">
            <b-button variant="warning" @click="modalJadwalOperasi"><i class="fas fa-eye"></i> Cek Jadwal Operasi</b-button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="">Tindakan Operasi<strong class="text-danger">*</strong></label>
        <div class="req-list-resume">
          <div class="row g-3">
          <template v-for="(v,k) in rowData.arancptlo_tindakan">
              <div class="col-md-4 col-lg-3" :key="k+'radioData'"
              v-if="isShowHeadOperasi(k)">
              <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                  </div>
                  <div class="card-body py-2">
                  <template v-for="v1,k1 in (v.data||[])">
                      <div v-if="isShowOperasi(k,k1)" :key="k1+'radiosubdatas'">
                      <i
                          class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                      <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                      
                      <a href="javascript:;" @click="openInformConsent(v1,k1,k,v.head)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo ml-2"  v-b-tooltip.hover :title="v1.inform_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                      <i class="icon-file-check2"></i>
                      </a>

                      <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,v.head)" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                      <i class="icon-file-eye2"></i>
                      </a>
                  
                      </div>
                  </template>
                  </div>
              </div>
              </div>
          </template>
          
          <div class="col-md-12">
            <div class="text-center mt-3">
              <a href="javascript:;" @click="doOpenOperasi" class="btn alpha-blue"><i
                  class="icon-plus2 mr-2"></i>Tambah Tindakan Operasi</a>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div>
            <label for="tindakanAnestesi">Tindakan Anestesi<strong
              class="text-danger">*</strong></label>
            <v-select 
              :options="Config.mr.tindakanAnestesi"
              v-model="rowData.arancptlo_tindakan_anestesi"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
          
            <VValidate 
                :name="'Tindakan Anestesi' +' Advis Ke #'+keyData" 
                v-model="rowData.arancptlo_tindakan_anestesi" 
                :rules="toValidate(isParent.mrValidation.arancptlo_tindakan_anestesi)"
            />
          </div>
        </div>
      </div>
      
      <div class="card-body">
        <div>
          <label for="">Apakah pasien akan dipasang implant?<strong class="text-danger">*</strong></label>
          <div>
            <b-form-radio-group 
            :options="Config.mr.yesNoOptV2" v-model="rowData.arancptlo_is_implant" />
            <VValidate :name="'Apakah pasien akan dipasang implant?' +' Advis Ke #'+keyData"
            v-model="rowData.arancptlo_is_implant" :rules="toValidate(isParent.mrValidation.arancptlo_is_implant)" />
          </div>
        </div>
      </div>
    </div>

    
    <b-modal v-model="openOperasi" :title="'Pilih Item Pemeriksaan Operasi'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in rowData.arancptlo_tindakan" :key="k" @click="changeTabOperasi(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabOperasi ? 'nav-link active' : 'nav-link'">{{v.head}}</a>
          </li>
          </ul>
      </div>

      <div class="modal-body p-0 mt-3">
          <div class="tab-content">
            <div v-for="(v,k) in rowData.arancptlo_tindakan" :key="k+'radform'"
                :class="k == activeTabOperasi ? 'tab-pane fade show active' : 'tab-pane fade'">
                <div class="row sc-row g-0">
                <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                    <div class="selection-control">
                    <b-form-checkbox @input="changeInpTindakanOperasi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                    </b-form-checkbox>
                    <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>

      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedOperasiInput">{{selectedOperasiInput}}</span>
          <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
          <div class="text-right">
          <button @click="openOperasi = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
      </div>
    </b-modal>
    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien` +' Advis Ke #'+keyData" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada` +' Advis Ke #'+keyData" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>
        
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>

    <b-modal id="modal_jadwal_operasi" title="Jadwal Operasi" size="xl" ok-only ok-title="Tutup">
      <b-overlay :show="loadingJadwal">
        <b-table
          :fields="fieldsJadwalOperasi"
          :items="dataModal.data || []"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <h4><span>Jadwal Operasi Tidak Tersedia</span></h4>
            </div>
          </template>
          <template #cell(number)="v">
              {{(dataModal.per_page*(dataModal.current_page-1))+v.index+1}}
            </template>
            
            <template #cell(waktu_operasi)="v">
              <span class="font-weight-semibold">{{v.item.arano_tanggal_operasi_start | moment('DD MMMM YYYY')}}</span><br><span>{{v.item.arano_jam_operasi_start}}</span>
            </template>

            <template #cell(nama)="v">
              <div>{{v.item.ap_fullname}} <br> {{v.item.ap_nik}}</div>
              <small class="text-secondary">Terdaftar {{v.item.ap_created_date | moment('DD/MM/yyyy HH:mm')}}</small>
            </template>

            <template #cell(jenis_tindakan)="v">
              <p>{{ v.value }}</p>
              <span>{{ v.item.data_jenis_tindakan }}</span>
            </template>

            <template #cell(ruangan)="v">
              <div class="font-weight-semibold">{{v.item.kamar}}</div>
              <small><span>{{v.item.bangsal}}</span> - <span>{{v.item.kelas}}</span></small>
            </template>

            <template #cell(status)="v">
              <div class="badge badge-success mb-1" v-if="v.value == 1">
                Terlaksana
              </div>
              <div class="badge badge-warning mb-1" v-else>
                Belum Terlaksana
              </div>
            </template> 
        </b-table>
        <b-pagination
          v-if="dataModal.last_page > 1"
          class="mb-0"
          v-model="page"
          :per-page="dataModal.per_page"
          :total-rows="dataModal.total"
        />
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker },
  props: {
    rowData: Object,
    keyData: Number,
  },
  data() {
    return {
      openOperasi: false,
      activeTabOperasi: 0,

      loadingJadwal: false,
      
      openModalICDetail: false,
      openModalIC: false,
      rowIC: {
        aranic_is_pasien_setuju: null,
        aranic_persetujuan_tindakan: null
      },
      fieldsJadwalOperasi: [
        {
            key: 'number',
            label: '#',
        },
        {
            key: 'waktu_operasi',
            label: 'Waktu Operasi',
        },
        {
            key: 'nama',
            label: 'Nama / NO KTP',
        },
        {
            key: 'ruangan',
            label: 'Ruangan',
        },
        {
            key: 'jenis_tindakan',
            label: 'Jenis Tindakan',
        },
        {
            key: 'kamar_operasi',
            label: 'Kamar Operasi',
        },
        {
            key: 'dokter_operator',
            label: 'Dokter Operator',
        },
        {
            key: 'dokter_anestesi',
            label: 'Dokter Anestesi',
        },
        {
            key: 'status',
            label: 'Status',
        },
      ],
      dataModal: {},
      page: 1,
      rencanaOperasi: []
    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    
    selectedOperasiInput() {
      let data = []
      for (let i = 0; i < (this.rowData.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (this.rowData.arancptlo_tindakan[i]['data'] || []).length; j++) {
            if (this.rowData.arancptlo_tindakan[i]['data'][j]['selected']) {
                data.push(this.rowData.arancptlo_tindakan[i]['data'][j]['text'])
            }
        }
      }
      return data.join(", ")
    }
  },
  methods: {
    
    salinTindakan(){
      let index = (this.rencanaOperasi||[]).findIndex(x => x.value == this.rowData.arancptlo_rencana_operasi_id)
      if (index !== -1) {
        let dataOperasi = this.rencanaOperasi[index]
        this.rowData.arancptlo_tanggal = dataOperasi.arancptlro_tanggal
        this.rowData.arancptlo_waktu = dataOperasi.arancptlro_waktu
        this.rowData.arancptlo_tindakan = dataOperasi.arancptlro_tindakan
        this.rowData.arancptlo_tindakan_anestesi = dataOperasi.arancptlro_tindakan_anestesi
        this.rowData.arancptlo_is_implant = dataOperasi.arancptlro_is_implant
      }
    },
    getRencanaOperasi(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'RanapCPPTSBAR', {
          data: {
            type: 'get-rencana-operasi',
            regId: this.rowData.arancp_aranr_id,
            rencana_operasi_id: this.rowData.arancptlo_rencana_operasi_id
          }
      }, 'POST').then(res => {
        this.loadingOverlay = false
        this.rencanaOperasi = res.data.rencanaOperasi
      })
    },
    getJadwal(){
      this.loadingJadwal = true
      Gen.apiRest("/get/AppRanapJadwalOperasi", {
        params: {
          status_terlaksana: 'N', // BYPASS hanya menampilkan status yang Belum Terlaksana
          all_date: 'Y',
          page: this.page,
          show_future: 'Y',
          shown: 10
        }
      })
      .then(res => {
        this.loadingJadwal = false
        console.log(res.data)
        this.$set(this, 'dataModal', res.data.data)
      })
      .catch(err => {
        this.loadingJadwal = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Terjadi suatu kesalahan!"
        })
      })
    },
    modalJadwalOperasi(){
      this.$bvModal.show('modal_jadwal_operasi')
      this.page = 1
      this.getJadwal()
    },
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
    
    doOpenOperasi() {
      this.openOperasi = true
    },
    changeTabOperasi(e) {
      this.activeTabOperasi = e
    },

    
    isShowHeadOperasi(j) {
      let isData = 0
      for (let k = 0; k < (this.rowData.arancptlo_tindakan[j]['data'] || []).length; k++) {
        if (this.rowData.arancptlo_tindakan[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowOperasi(j, k) {
        let isData = 0
        if (this.rowData.arancptlo_tindakan[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },
    resetOperasi() {
        for (let i = 0; i < (this.rowData.arancptlo_tindakan || []).length; i++) {
            for (let j = 0; j < (this.rowData.arancptlo_tindakan[i]['data'] || []).length; j++) {
                this.rowData.arancptlo_tindakan[i]['data'][j]['selected'] = false
            }
        }
    },

    
    openInformConsentDetail(v,k1,k,kat){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
                type: 'get-data-ic-single',
                id: v.inform_consent,
                isOperasi: true,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
              
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.openModalICDetail = true                   
        })
    },
    openInformConsent(v,k1,k,kat){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
                type: 'get-data-ic-single',
                id: v.inform_consent,
                isOperasi: true,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent   
            this.rowIC.k1 = k1
            this.rowIC.k = k         
        })
        // console.log(edit)
        this.openModalIC = true
    },

    submitInformConcent(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false
                
                let data = this.rowIC
                data.type = 'submit-data-inform-consent-single'
                data.aranic_aranr_id = this.rowData.arancp_aranr_id
                data.aranic_name = this.rowIC.tindakan
                data.aranic_created_on = 'Operasi'

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RanapCPPTDokter', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.rowData['arancptlo_tindakan'][this.rowIC.k]['data'][this.rowIC.k1]['inform_consent'] = res.data
                    this.loadingOverlay = false
                    this.isParent.autoSaveCPPTDokter(this.rowData)

                    this.rowIC.aranic_id = null
                    
                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    },


    
    changeInpTindakanOperasi(e,v,kU,k){
      console.log(e,v,kU,k)
      if(!e && v.inform_consent){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda yakin ingin menghapus tindakan ini, Tindakan telah mempunyai Inform consent?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
              if(result.value){
                  this.loadingOverlay = true                        
                  let data = {
                      type: "remove-ic-single",
                      id: v.inform_consent
                  }
                  Gen.apiRest(
                      "/do/"+'RanapCPPTDokter', 
                      {
                          data: data
                      }, "POST"
                  ).then(()=>{
                      this.loadingOverlay = false                                            
                      this.rowData.arancptlo_tindakan[kU]['data'][k]['inform_consent'] = null                    
                  })
              }
              else{
                  this.rowData.arancptlo_tindakan[kU]['data'][k]['selected'] = true

              }
          })
      }
    },
  },     
  watch: {
    page() {
      this.getJadwal()
    }
  },
  mounted() {
    this.getRencanaOperasi()
  },
}

</script>